<template>
  <v-card>
    <v-card-title>
      <span class="text-h5"> 設定任務關卡 － </span>
      <span class="text-h5">
        {{ missionName }}
      </span>
    </v-card-title>
    <v-card-text>
      <v-container v-if="editItem">
        <v-form ref="editForm" lazy-validation>
          <v-row class="mx-auto">
            <v-col cols="12">
              <v-text-field
                v-model="editItem.missionName"
                label="關卡名稱"
                :rules="rules.required"
                class="font-size-input-lg input-style"
                :disabled="isActionDisabled"
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-textarea
                v-model="editItem.missionDesc"
                outlined
                label="關卡描述"
                rows="3"
                class="font-size-input-lg input-style"
              ></v-textarea>
              <!-- <v-text-field
                v-model="editItem.missionDesc"
                label="關卡描述"
                :rules="rules.required"
                :disabled="isActionDisabled"
              ></v-text-field> -->
            </v-col>

            <v-col cols="12" sm="6">
              <v-text-field
                v-model="editItem.data.requiredSeconds"
                label="觀看時間（秒)"
                type="number"
                :rules="rules.positiveInteger"
                class="font-size-input-lg input-style"
                :disabled="isActionDisabled"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6">
              <v-select
                v-model="editItem.data.repetitionMode"
                label="重複觀看間隔"
                :items="videoRepetitionModes"
                item-text="name"
                item-value="value"
                class="font-size-input-lg input-style"
                clearable
                :disabled="isActionDisabled"
              >
              </v-select>
            </v-col>
            <v-col cols="12" sm="6">
              <v-select
                v-model="editItem.data.rewardId"
                label="關卡獎勵"
                :items="availableRewards"
                item-text="rewardName"
                item-value="rewardId"
                class="font-size-input-lg input-style"
                :disabled="isActionDisabled"
              >
              </v-select>
            </v-col>
            <v-col cols="12" sm="6" v-show="showMultiCollectible">
              <v-checkbox
                v-model="editItem.data.multiCollectible"
                color="#141727"
                class="ma-0 large-checkbox pb-2"
                :class="$vuetify.breakpoint.xs ? '' : 'pt-6'"
                hide-details
                label="可重複領獎"
                :disabled="isActionDisabled"
              >
              </v-checkbox>
            </v-col>
            <v-col cols="12">
              <v-text-field
                v-model="videoUrl"
                label="影片網址"
                class="font-size-input-lg input-style"
                :rules="rules.isUrl"
                :disabled="isActionDisabled"
              >
                <template v-slot:append-outer>
                  <v-btn @click="addVideoUrl" small dark class="mx-2">
                    <v-icon> fa-lg fa-plus </v-icon>
                  </v-btn>
                </template>
              </v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-list-item v-for="(url, index) in videoUrls" :key="index">
              <v-list-item-title>
                影片 {{ index + 1 }}：{{ url }}
              </v-list-item-title>

              <v-list-item-action>
                <v-btn
                  class="mx-2"
                  dark
                  fab
                  x-small
                  color="pink darken-2"
                  @click="removeVideoUrl(index)"
                >
                  <v-icon>fas fa-trash</v-icon>
                </v-btn>
              </v-list-item-action>
            </v-list-item>
          </v-row>
        </v-form>
      </v-container>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
        color="blue darken-1"
        text
        :disabled="isActionDisabled"
        @click="closeEditDialog"
      >
        {{ $t(`common['Close']`) }}
      </v-btn>
      <v-btn
        color="blue darken-1"
        text
        :disabled="isActionDisabled"
        :loading="isProcessing"
        @click="onSaveEditItem"
      >
        {{ $t(`common['Save']`) }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import moment from "moment";
import { mapState } from "vuex";

import { fetchCampaignRewards, updateMission } from "src/apis/campaignCore";
import { isURL, isPositiveInteger } from "src/util/utils";
import variables from "src/util/variables";

export default {
  name: "VideoForm",
  props: {
    inputItem: {
      type: Object,
      default: function () {
        return {};
      },
      required: true,
    },
    missionName: {
      type: String,
      default: "",
      required: true,
    },
    campaignId: {
      type: String,
      default: null,
      required: true,
    },
  },
  components: {},
  data() {
    return {
      moment: moment,
      editItem: null,
      isProcessing: false,
      isLoadingRewards: false,
      availableRewards: [],
      //availableVisibilityOptions: [],
      rules: {
        required: [(v) => !!v || this.$t(`common["Required"]`)],
        isUrl: [
          (value) => isURL(value) || this.$t(`common["URL is not valid"]`),
        ],
        positiveInteger: [
          (v) => !!v || this.$t(`common["Required"]`),
          (v) =>
            isPositiveInteger(v) ||
            this.$t(`validation["Must be positive integer"]`),
        ],
      },
      videoUrl: null,
      videoUrls: [],
      showMultiCollectible: false,
    };
  },
  computed: {
    ...mapState({
      isLoggedIn: (state) => state.isLoggedIn,
      ssoUser: (state) => state.ssoUser,
    }),
    isActionDisabled() {
      return this.isProcessing || this.isLoadingRewards;
    },
    merchantId() {
      return this.isLoggedIn ? this.ssoUser.user._json.groups.merchant : null;
    },
    organizationId() {
      return this.isLoggedIn
        ? this.ssoUser.user._json.groups.organization
        : null;
    },
    isNew() {
      return this.editItem.missionId ? false : true;
    },
    // availableVisibilityOptions() {
    //   return variables.missionVisibiltyOptions;
    // },
    videoRepetitionModes() {
      return variables.videoRepetitionModes;
    },
  },
  watch: {},
  created() {},
  mounted() {
    this.editItem = Object.assign({}, this.inputItem);
    console.log("videoForm:" + JSON.stringify(this.editItem));
    if (this.editItem.missionId) {
      this.editItem.data = Object.assign({}, this.editItem.settings);
    }

    if (this.editItem.missionId && this.editItem.data.videoUrls) {
      this.videoUrls = [...this.editItem.data.videoUrls];
    } else {
      this.videoUrls = [];
    }

    this.getRewards();
  },
  methods: {
    getRewards() {
      this.isLoadingRewards = true;
      let query = {};
      console.log("getRewards campaign:" + this.campaignId);
      fetchCampaignRewards(this.merchantId, this.campaignId, query)
        .then((res) => {
          console.log("fetchCampaignRewards done", res);
          this.availableRewards = [...res.data];
        })
        .catch((e) => {
          console.log("fetchCampaignRewards failed", e);
        })
        .finally(() => {
          this.isLoadingRewards = false;
        });
    },
    closeEditDialog() {
      this.$refs.editForm.resetValidation();
      this.$refs.editForm.reset();
      this.$emit("close");
    },
    onSaveEditItem() {
      const valid = this.$refs.editForm.validate();
      this.editItem.name = this.editItem.missionName;
      this.editItem.desc = this.editItem.missionDesc;
      this.editItem.data.videoUrls = [...this.videoUrls];

      this.isProcessing = true;
      if (valid) {
        // this.$emit("save");
        if (!this.editItem.data.videoUrls.length) {
          this.showErrorAlert("請填寫影片連結");
          this.isProcessing = false;
          return;
        }
        updateMission(this.merchantId, this.campaignId, this.editItem)
          .then((res) => {
            console.log("updateMission done", res);
            if (res && res.data) {
              this.editItem.missionId = res.data.missionId;
            }
            // this.closeEditDialog();
            this.$refs.editForm.resetValidation();
            this.$refs.editForm.reset();
            this.$emit("save");
          })
          .catch((e) => {
            console.log("updateMission failed", e);
            let errmsg = this.isNew ? "新增任務關卡失敗" : "更新任務關卡失敗";
            if (e.response && e.response.data && e.response.data.message) {
              errmsg += "<br/>" + e.response.data.message;
            }
            this.showErrorAlert(errmsg);
          })
          .finally(() => {
            this.isProcessing = false;
          });
      } else {
        this.showErrorAlert(this.$i18n.t(`errors["Incorrect Fields"]`));
        this.isProcessing = false;
      }
    },
    showErrorAlert(message) {
      let title = this.$i18n.t(`errors["Update"]`);

      if (this.isNew) {
        title = this.$i18n.t(`errors["Create"]`);
      }
      this.$swal({
        title: title,
        html: message,
        type: "error",
        showCancelButton: false,
        confirmButtonText: this.$i18n.t(`common["Confirm"]`),
        customClass: {
          confirmButton: "btn bg-gradient-success",
          cancelButton: "btn bg-gradient-danger",
        },
      });
    },
    addVideoUrl() {
      if (!this.videoUrl) return;

      const duplicated = this.videoUrls.filter(
        (e) => e.trim() === this.videoUrl.trim()
      );
      // const duplicated = this.rewards.filter(
      //   (e) => e === this.inputReward.rewardId
      // );
      if (duplicated.length > 0) {
        this.$swal({
          text: "影片連結重複",
          type: "error",
          showCancelButton: false,
          buttonStyle: false,
          confirmButtonText: this.$i18n.t(`common["Confirm"]`),
          customClass: {
            confirmButton: "btn bg-gradient-success",
            cancelButton: "btn bg-gradient-danger",
          },
        });
        return;
      }

      this.videoUrls.push(this.videoUrl);
      this.videoUrl = null;
    },
    removeVideoUrl(index) {
      this.videoUrls.splice(index, 1);
    },
  },
};
</script>
<style scoped>
.large-checkbox::v-deep i {
  font-size: 24px;
}
.large-checkbox::v-deep label {
  font-size: 16px;
  padding-left: 3px;
}
.large-checkbox::v-deep .v-radio {
  padding: 0px;
}
</style>
