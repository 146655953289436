<template>
  <div>
    <v-container fluid class="px-6 py-6">
      <v-row>
        <v-col md="12">
          <v-card class="mb-6 card-shadow border-radius-xl py-4">
            <v-card-text>
              <v-row>
                <v-col cols="12" sm="4" md="2">
                  <v-select
                    v-model="selectedOrganizationId"
                    :items="availableOrganizations"
                    item-text="organizationName"
                    item-value="organizationId"
                    :label="$t(`common['Select Organization']`)"
                    :menu-props="{ closeOnContentClick: true }"
                    :disabled="!permissionScope.organization || !isLoaded"
                    @change="getDepartmentList"
                    :no-data-text="$t(`common['No Data']`)"
                  >
                    <template v-slot:prepend-item>
                      <v-list-item
                        ripple
                        @click="
                          selectedOrganizationId = null;
                          selectedDepartmentId = null;
                          departments = [];
                        "
                      >
                        <v-list-item-content>
                          <v-list-item-title>
                            {{ $t(`common['Nothing to select']`) }}
                          </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                      <v-divider class="mt-2"></v-divider>
                    </template>
                  </v-select>
                </v-col>

                <v-col cols="12" sm="4" md="2">
                  <v-select
                    v-model="selectedDepartmentId"
                    :items="departments"
                    item-text="departmentName"
                    item-value="id"
                    label="單位"
                    :disabled="!permissionScope.department || !isLoaded"
                    :menu-props="{ closeOnContentClick: true }"
                    :no-data-text="$t(`common['No Data']`)"
                  >
                    <template v-slot:prepend-item>
                      <v-list-item ripple @click="selectedDepartmentId = null">
                        <v-list-item-content>
                          <v-list-item-title>
                            {{ $t(`common['Nothing to select']`) }}
                          </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                      <v-divider class="mt-2"></v-divider>
                    </template>
                  </v-select>
                </v-col>
                <v-col cols="12" sm="4" md="2">
                  <v-select
                    :items="reviewStatusOptions"
                    v-model="selectedReviewStatus"
                    label="審核狀態"
                    item-text="name"
                    item-value="value"
                    :disabled="!isLoaded"
                    :menu-props="{ closeOnContentClick: true }"
                  >
                    <template v-slot:prepend-item>
                      <v-list-item ripple @click="reviewOptions = null">
                        <v-list-item-content>
                          <v-list-item-title>
                            {{ $t(`common['Nothing to select']`) }}
                          </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                      <v-divider class="mt-2"></v-divider>
                    </template>
                  </v-select>
                </v-col>
                <v-col cols="12" sm="4">
                  <v-row>
                    <v-col cols="6">
                      <v-select
                        :items="years"
                        v-model="selectedYear"
                        label="任務期間-年份"
                        :disabled="!isLoaded"
                        :menu-props="{ closeOnContentClick: true }"
                      >
                        <template v-slot:prepend-item>
                          <v-list-item ripple @click="selectedYear = null">
                            <v-list-item-content>
                              <v-list-item-title>
                                {{ $t(`common['Nothing to select']`) }}
                              </v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                          <v-divider class="mt-2"></v-divider>
                        </template>
                      </v-select>
                    </v-col>
                    <v-col cols="6">
                      <v-select
                        :items="months"
                        v-model="selectedMonth"
                        item-text="name"
                        item-value="value"
                        label="任務期間-月份"
                        :disabled="!isLoaded"
                        :menu-props="{ closeOnContentClick: true }"
                      >
                        <template v-slot:prepend-item>
                          <v-list-item ripple @click="selectedMonth = null">
                            <v-list-item-content>
                              <v-list-item-title>
                                {{ $t(`common['Nothing to select']`) }}
                              </v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                          <v-divider class="mt-2"></v-divider>
                        </template>
                      </v-select>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col
                  cols="12"
                  sm="4"
                  md="2"
                  class="d-flex align-center"
                  :class="{
                    'pt-5': $vuetify.breakpoint.md || $vuetify.breakpoint.lg,
                  }"
                >
                  <div>
                    <v-btn
                      class="
                        font-weight-normal
                        text-capitalize
                        btn-primary
                        bg-gradient-secondary
                        py-3
                        px-6
                        ms-3
                      "
                      @click="
                        pagination.page = 1;
                        getDataFromApi();
                      "
                      :disabled="!isLoaded"
                      :loading="!isLoaded"
                    >
                      {{ $t('common["Search"]') }}</v-btn
                    >
                  </div>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-card>
            <v-card-title>
              任務列表
              <v-spacer></v-spacer>
              <!-- 需要後台filter -->
              <v-text-field
                v-model="search"
                append-icon="fa-search"
                label="搜尋任務名稱/描述"
                single-line
                hide-details
              ></v-text-field>
              <span class="pt-5" v-if="canEdit">
                <!-- <v-btn
                  class="
                    font-weight-normal
                    text-capitalize
                    btn-primary
                    bg-gradient-secondary
                    py-3
                    px-6
                    ms-3
                  "
                  :disabled="!isLoaded"
                  @click="onEditItem(null)"
                >
                  {{ $t(`common["Add"]`) }}
                </v-btn> -->
                <v-dialog
                  v-model="campaignTypeDialog"
                  persistent
                  max-width="800px"
                >
                  <template v-slot:activator="{ on }">
                    <v-btn
                      class="
                        font-weight-normal
                        text-capitalize
                        btn-primary
                        bg-gradient-secondary
                        py-3
                        px-6
                        ms-3
                      "
                      v-on="on"
                      :disabled="!isCampaignTypesLoaded || !isLoaded"
                    >
                      {{ $t(`common["Add"]`) }}
                    </v-btn>
                  </template>
                  <v-card>
                    <v-card-title>
                      <span class="headline">請選擇任務類型</span>
                    </v-card-title>

                    <v-card-text>
                      <v-container>
                        <v-form ref="campaignTypeForm">
                          <v-row>
                            <v-col
                              v-for="type in filteredCampaignTypes"
                              :key="type.type"
                              cols="12"
                              sm="6"
                            >
                              <v-hover v-slot="{ hover }">
                                <v-card
                                  :class="{ 'on-hover': hover }"
                                  class="
                                    px-4
                                    py-4
                                    card-shadow
                                    border-radius-xl
                                    h-100
                                  "
                                >
                                  <div
                                    class="
                                      d-flex
                                      flex-column
                                      justify-center
                                      text-center
                                      h-100
                                    "
                                  >
                                    <a
                                      @click="
                                        onEditItem(null, type.type);
                                        campaignTypeDialog = false;
                                      "
                                      class="text-decoration-none"
                                    >
                                      <h5 class="text-h5 text-secondary">
                                        {{ type.name }}
                                      </h5>
                                      <p
                                        class="
                                          text-sm
                                          mt-2
                                          mb-1
                                          text-capitalize text-body
                                          font-weight-light
                                        "
                                      >
                                        {{ type.description }}
                                      </p>
                                    </a>
                                  </div>
                                </v-card>
                              </v-hover>
                            </v-col>
                          </v-row>
                        </v-form>
                      </v-container>
                    </v-card-text>

                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn
                        color="blue darken-1"
                        text
                        @click="campaignTypeDialog = false"
                      >
                        {{ $t(`common['Cancel']`) }}
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </span>
            </v-card-title>
            <v-card-text>
              <v-data-table
                ref="campaignTable"
                :headers="tableHeaders"
                :items="tableData"
                :loading="!isLoaded"
                :loading-text="$t(`common['Loading... Please wait']`)"
                :no-data-text="$t(`common['No Data']`)"
                :single-expand="singleExpand"
                :expanded.sync="expanded"
                item-key="id"
                :show-expand="true"
                :options.sync="pagination"
                :server-items-length="total"
                @item-expanded="loadDetails"
                :footer-props="{
                  'items-per-page-text': $t(`common['Rows per page']`),
                }"
                :page.sync="pagination.page"
                hide-default-footer
                @page-count="pagination.pageCount = $event"
                :items-per-page="pagination.itemsPerPage"
              >
                <template v-slot:expanded-item="{ headers, item }">
                  <td :colspan="headers.length">
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title>
                          任務類型：{{ item.prototypeName }}
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title>
                          任務可見度：
                          {{
                            item.visibility === "public"
                              ? $t(`common['Public']`)
                              : $t(`common['Hidden']`)
                          }}
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title>任務描述</v-list-item-title>
                        <v-list-item-subtitle>
                          <div
                            :class="
                              $vuetify.breakpoint.xs || $vuetify.breakpoint.sm
                                ? 'text-truncate'
                                : 'text-wrap-whitespace'
                            "
                            :style="{
                              'max-width':
                                $vuetify.breakpoint.xs || $vuetify.breakpoint.sm
                                  ? '60vw'
                                  : '90vw',
                            }"
                          >
                            {{ item.description }}
                          </div>
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>

                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title>任務奬勵</v-list-item-title>
                        <v-list-item-subtitle>
                          <v-data-table
                            :headers="tableRewardHeaders"
                            :items="missionRewards"
                            :loading-text="
                              $t(`common['Loading... Please wait']`)
                            "
                            :no-data-text="$t(`common['No Data']`)"
                            item-key="id"
                            :hide-default-footer="true"
                            :disable-filtering="true"
                            :disable-pagination="true"
                            :disable-sort="true"
                          >
                            <template v-slot:item.multiCollectible="{ item }">
                              <v-icon
                                v-if="item.multiCollectible"
                                color="green darken-2"
                              >
                                fa-solid fa-check
                              </v-icon>
                              <v-icon v-else color="red darken-2">
                                fa-times
                              </v-icon>
                            </template>
                            <template v-slot:item.context="{ item }">
                              <span v-if="item.type === 'points'">
                                <span v-if="item.context.undetermined">
                                  動態指定獎勵點數
                                </span>
                                <span v-else
                                  >{{ item.context.amount }} 點
                                </span>
                              </span>
                              <span v-if="item.type === 'coupon'">
                                優惠券 {{ r.context.couponId }} ：
                                {{ item.context.amount }} 張
                              </span>
                              <span v-if="item.type === 'misc'">
                                {{ r.context.misc }} x{{ item.context.amount }}
                              </span>
                            </template>
                            <template v-slot:item.totalStock="{ item }">
                              {{ item.totalStock || "無限制" }}
                            </template>
                            <template v-slot:item.rewardCounts="{ item }">
                              {{ formatNumber(item.rewardCounts, 0) }}
                            </template>
                            <template v-slot:item.createdAt="{ item }">
                              {{
                                item.createdAt
                                  ? moment
                                      .unix(item.createdAt)
                                      .format("YYYY/MM/DD")
                                  : ""
                              }}
                            </template>
                          </v-data-table>
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                    <v-spacer></v-spacer>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title>關卡內容</v-list-item-title>
                        <v-list-item-subtitle>
                          <v-data-table
                            :headers="tableMissionHeaders"
                            :items="missions"
                            :loading-text="
                              $t(`common['Loading... Please wait']`)
                            "
                            :no-data-text="$t(`common['No Data']`)"
                            item-key="id"
                            :hide-default-footer="true"
                            :disable-filtering="true"
                            :disable-pagination="true"
                            :disable-sort="true"
                          >
                            <template v-slot:item.type="{ item }">
                              {{ getMissionType(item.type) }}
                              <v-btn
                                v-if="
                                  item.type === 'code-exchange' &&
                                  expandedCampaign.campaignStatus === 'approved'
                                "
                                class="ms-3"
                                :outlined="!$vuetify.breakpoint.mobile"
                                :icon="$vuetify.breakpoint.mobile"
                                :x-small="$vuetify.breakpoint.mobile"
                                color="blue darken-2"
                                @click="exportEntryCode(item.id)"
                              >
                                <v-icon v-if="$vuetify.breakpoint.mobile"
                                  >fas fa-download</v-icon
                                >

                                <span v-else>下載活動代碼</span>
                              </v-btn>
                            </template>
                            <template v-slot:item.missionName="{ item }">
                              <div class="text-wrap" style="max-width: 30vw">
                                {{ item.missionName }}
                              </div>
                            </template>
                            <template v-slot:item.repetitionMode="{ item }">
                              <span v-if="item.repetitionMode === 'freely'">
                                不限制
                              </span>
                              <span v-else-if="item.repetitionMode === 'daily'">
                                每日一次
                              </span>
                              <span v-else>不可重複</span>
                            </template>
                            <template v-slot:item.description="{ item }">
                              <div
                                :class="
                                  $vuetify.breakpoint.xs ||
                                  $vuetify.breakpoint.sm
                                    ? 'text-truncate'
                                    : 'text-wrap-whitespace'
                                "
                                style="max-width: 30vw"
                              >
                                {{ item.description }}
                              </div>
                            </template>
                            <template v-slot:item.createdAt="{ item }">
                              {{
                                item.createdAt
                                  ? moment
                                      .unix(item.createdAt)
                                      .format("YYYY/MM/DD")
                                  : ""
                              }}
                            </template>
                          </v-data-table>
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </td>
                </template>
                <template v-slot:item.campaignStatus="{ item }">
                  <span v-if="item.campaignStatus === 'approved'">
                    審核通過
                  </span>
                  <span v-else-if="item.campaignStatus === 'rejected'">
                    審核駁回
                  </span>
                  <span v-else-if="canAudit">
                    <v-select
                      :items="reviewOptions"
                      v-model="item.reviewStatus"
                      item-text="name"
                      item-value="value"
                      item-disabled="disabled"
                      @change="onClickCampaignStatusChange(item)"
                    >
                    </v-select>
                  </span>
                  <span v-else>
                    {{ item.campaignStatus === "draft" ? "草稿" : "送審中" }}
                  </span>
                </template>
                <template v-slot:item.actualPoints="{ item }">
                  {{ formatNumber(item.actualPoints, 0) }}
                </template>
                <template v-slot:item.availablePoints="{ item }">
                  {{ formatNumber(item.availablePoints, 0) }}
                </template>
                <template v-slot:item.usedPoints="{ item }">
                  {{ formatNumber(item.usedPoints, 0) }}
                </template>
                <template v-slot:item.participants="{ item }">
                  {{ formatNumber(item.participants, 0) }}
                </template>
                <template v-slot:item.startTime="{ item }">
                  <span>{{
                    item.startTime
                      ? moment
                          .unix(item.startTime)
                          .format("YYYY/MM/DD HH:mm:ss")
                      : ""
                  }}</span>
                </template>
                <template v-slot:item.endTime="{ item }">
                  <span>{{
                    item.endTime
                      ? moment.unix(item.endTime).format("YYYY/MM/DD HH:mm:ss")
                      : ""
                  }}</span>
                </template>
                <template v-slot:item.createdAt="{ item }">
                  <span>{{
                    item.createdAt
                      ? moment
                          .unix(item.createdAt)
                          .format("YYYY/MM/DD HH:mm:ss")
                      : ""
                  }}</span>
                </template>
                <template v-slot:item.updatedAt="{ item }">
                  <span>{{
                    item.updatedAt
                      ? moment
                          .unix(item.updatedAt)
                          .format("YYYY/MM/DD HH:mm:ss")
                      : ""
                  }}</span>
                </template>
                <template v-slot:item.actions="props">
                  <v-tooltip bottom v-if="canEdit">
                    <template v-slot:activator="{ on }">
                      <v-btn
                        class="mx-2 mt-2"
                        dark
                        fab
                        x-small
                        :color="
                          props.item.campaignStatus === 'draft' &&
                          (!props.item.isAdvanced ||
                            (canEditAdvancedCampaign && props.item.isAdvanced))
                            ? 'cyan'
                            : '#DBDCDC'
                        "
                        :style="{
                          cursor:
                            props.item.campaignStatus === 'draft' &&
                            (!props.item.isAdvanced ||
                              (canEditAdvancedCampaign &&
                                props.item.isAdvanced))
                              ? 'pointer'
                              : 'not-allowed',
                        }"
                        v-on="on"
                        @click="
                          props.item.campaignStatus === 'draft' &&
                          (!props.item.isAdvanced ||
                            (canEditAdvancedCampaign && props.item.isAdvanced))
                            ? onEditItem(props.item)
                            : ''
                        "
                      >
                        <v-icon>fas fa-edit</v-icon>
                      </v-btn>
                    </template>
                    <span> {{ $t(`common['Edit']`) }}</span>
                  </v-tooltip>
                  <v-tooltip bottom v-if="canEditManager">
                    <template v-slot:activator="{ on }">
                      <v-btn
                        class="mx-2 mt-2"
                        dark
                        fab
                        x-small
                        color="blue darken-2"
                        v-on="on"
                        @click="onInviteClick(props.item)"
                      >
                        <v-icon>fas fa-user-plus</v-icon>
                      </v-btn>
                    </template>
                    <span>新增/邀請管理人員</span>
                  </v-tooltip>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-btn
                        class="mx-2 mt-2"
                        dark
                        fab
                        x-small
                        color="purple darken-2"
                        v-on="on"
                        @click="onOpenParticipantDialog(props.item)"
                      >
                        <!-- <v-icon>fas fa-users</v-icon> -->
                        <v-icon
                          class="material-icons-round text-white"
                          size="24"
                          >donut_small</v-icon
                        >
                      </v-btn>
                    </template>
                    <span> 查看任務參與狀況 </span>
                  </v-tooltip>
                  <v-tooltip bottom v-if="canEdit">
                    <template v-slot:activator="{ on }">
                      <v-btn
                        class="mx-2 mt-2"
                        dark
                        fab
                        x-small
                        :color="
                          props.item.campaignStatus === 'draft'
                            ? 'pink darken-2'
                            : '#DBDCDC'
                        "
                        :style="{
                          cursor:
                            props.item.campaignStatus === 'draft'
                              ? 'pointer'
                              : 'not-allowed',
                        }"
                        v-on="on"
                        @click="
                          props.item.campaignStatus === 'draft'
                            ? onDeleteCampaignClick(props.item)
                            : ''
                        "
                      >
                        <v-icon>fas fa-trash</v-icon>
                      </v-btn>
                    </template>
                    <span> {{ $t(`common['Delete']`) }}</span>
                  </v-tooltip>
                </template>
              </v-data-table>
            </v-card-text>
            <v-card-actions class="card-padding" v-if="total > 0">
              <Pagination
                :pagination="pagination"
                :total="total"
                :loading="!isLoaded"
                showItemsPerPage
                dataTable="campaignTable"
                @update="getDataFromApi"
              ></Pagination>
              <!-- <v-row class="text-center px-4 align-center" wrap>
                <v-col cols="12" class="ml-auto d-flex justify-center">
                  <v-pagination
                    prev-icon="fa fa-angle-left"
                    next-icon="fa fa-angle-right"
                    class="pagination"
                    color="#D81B60"
                    v-model="pagination.page"
                    :length="pagination.pageCount"
                    :total-visible="$vuetify.breakpoint.mobile ? 5 : 10"
                    circle
                  ></v-pagination>
                </v-col>
                <v-col
                  class="text-truncate d-flex justify-end"
                  cols="12"
                  md="3"
                >
                  共 {{ total }} 筆資料
                </v-col>
                <v-col cols="6" md="3" class="d-flex align-center">
                  <v-select
                    v-model="pagination.itemsPerPage"
                    :items="[5, 10, 20, 50, 100]"
                    :label="$t(`common['Rows per page']`)"
                    dense
                    outlined
                    hide-details
                    @change="pagination.itemsPerPage = parseInt($event, 10)"
                  >
                  </v-select>
                </v-col>

                <v-col cols="6" md="3">
                  <v-text-field
                    v-model="page"
                    :label="$t(`common['Go to page']`)"
                    type="number"
                    outlined
                    hide-details
                    dense
                    @input="page = parseInt($event, 10)"
                  ></v-text-field>
                </v-col>
              </v-row> -->
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>

      <v-dialog
        v-model="openEditDialog"
        persistent
        no-click-animation
        fullscreen
      >
        <Wizard
          v-if="openEditDialog"
          :campaign="editItem"
          @close="onCloseEditDialog"
          @updated="onCampaignUpdated"
        ></Wizard>
      </v-dialog>

      <v-dialog
        v-model="openParticipantDialog"
        persistent
        no-click-animation
        fullscreen
      >
        <ParticipantList
          v-if="openParticipantDialog"
          :campaign="selectedCampaign"
          @close="onCloseParticipantDialog"
        ></ParticipantList>
      </v-dialog>

      <v-dialog v-model="openInviteDialog" max-width="600px">
        <v-card>
          <v-card-title>
            <span class="headline">新增/邀請管理人員</span>
          </v-card-title>

          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="6">
                  <v-select
                    v-model="permissionRole"
                    :items="permissionRoles"
                    item-text="title"
                    item-value="value"
                    label=""
                  ></v-select>
                </v-col>
                <v-col cols="6" class="pt-6">
                  <v-btn
                    class="ms-3"
                    dark
                    medium
                    :color="permissionRole ? 'blue darken-2' : '#DBDCDC'"
                    :style="{
                      cursor: permissionRole ? 'pointer' : 'not-allowed',
                    }"
                    @click="permissionRole ? generateInviteUrl() : null"
                  >
                    產生邀請連結
                  </v-btn>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="9">
                  <v-text-field
                    v-model="inviteUrl"
                    type="text"
                    label="邀請連結"
                    :disabled="true"
                  ></v-text-field>
                </v-col>

                <v-col cols="3" class="pt-6">
                  <v-btn
                    class="ms-3"
                    dark
                    medium
                    :color="inviteUrl ? 'blue darken-2' : '#DBDCDC'"
                    :style="{
                      cursor: inviteUrl ? 'pointer' : 'not-allowed',
                    }"
                    @click="inviteUrl ? copyInviteUrl() : null"
                  >
                    <!-- <v-icon>fa-lg fa-copy</v-icon> -->
                    複製邀請連結
                  </v-btn>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="closeInviteDialog">
              {{ $t(`common['Close']`) }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-container>
  </div>
</template>

<script>
import moment from "moment";
import { mapState } from "vuex";

import {
  fetchDepartments,
  fetchCampaigns,
  fetchCampaignMissions,
  fetchCampaignRewards,
  fetchOrganizations,
  exportCampaignEntryCode,
} from "src/apis/fetchData";
import { formatNumber, checkPermission, s2ab } from "src/util/utils";
import { generateArrayOfYears, generateArrayOfMonths } from "src/util/utils";
import {
  deleteCampaign,
  submitCampaign,
  approveCampaign,
  rejectCampaign,
  fetchMissionTypes,
  fetchCampaignTypes,
  fetchRewardTypes,
} from "src/apis/campaignCore";
import { generateCampaignManagerInviteUrl } from "src/apis/identitiesCore";
import Wizard from "./Widgets/Wizard.vue";
import ParticipantList from "./Widgets/ParticipantList.vue";
import Pagination from "./Widgets/Pagination.vue";

export default {
  name: "CampaignList",
  components: { Wizard, ParticipantList, Pagination },
  data() {
    return {
      moment: moment,
      formatNumber: formatNumber,
      reviewStatusOptions: [
        {
          name: "審核通過",
          value: "approved",
        },
        {
          name: "審核駁回",
          value: "rejected",
        },
      ],
      reviewOptions: [
        {
          name: "草稿",
          value: "draft",
          disabled: true,
        },
        {
          name: "送審中",
          value: "submit",
          disabled: false,
        },
        {
          name: "審核通過",
          value: "approved",
          disabled: false,
        },
        {
          name: "審核駁回",
          value: "rejected",
          disabled: false,
        },
      ],
      isLoaded: true,
      isNew: false,
      viewOnly: false,
      openApproveDialog: false,
      openEditDialog: false,
      openParticipantDialog: false,
      selectedCampaign: {},
      singleExpand: true,
      expanded: [],
      total: 0,
      tableData: [],
      tableHeaders: [
        // {
        //   text: "ID",
        //   sortable: true,
        //   value: "id",
        // },
        {
          text: "單位",
          sortable: true,
          value: "departmentName",
        },
        {
          text: "ID",
          sortable: false,
          value: "id",
        },
        {
          text: "類型",
          sortable: false,
          value: "prototypeName",
        },
        {
          text: "名稱",
          sortable: true,
          value: "campaignName",
        },
        {
          text: "預算名稱",
          sortable: true,
          value: "campaignBudgetName",
        },
        {
          text: "點數預算",
          sortable: true,
          align: "right",
          // value: "allocatedPoints",
          value: "actualPoints",
        },
        {
          text: "已發點數",
          sortable: true,
          align: "right",
          value: "usedPoints",
        },
        {
          text: "未發點數",
          sortable: true,
          align: "right",
          value: "availablePoints",
        },
        {
          text: "參與人數",
          sortable: true,
          align: "right",
          value: "participants",
        },
        // {
        //   text: "描述",
        //   sortable: true,
        //   filterable: false,
        //   value: "description",
        // },
        {
          text: "任務狀態",
          sortable: true,
          filterable: false,
          value: "campaignStatus",
        },
        {
          text: "開始時間",
          sortable: true,
          filterable: false,
          value: "startTime",
        },
        {
          text: "結束時間",
          sortable: true,
          filterable: false,
          value: "endTime",
        },
        {
          text: "建立時間",
          sortable: true,
          filterable: false,
          value: "createdAt",
        },
        {
          text: "更新時間",
          sortable: true,
          filterable: false,
          value: "updatedAt",
        },

        { text: "", value: "actions", sortable: false },
      ],
      search: null,
      rules: {
        required: [(v) => !!v || this.$t(`common["Required"]`)],
      },
      years: [],
      months: [],
      selectedYear: null,
      selectedMonth: null,
      departments: [],
      selectedDepartmentId: null,
      availableOrganizations: [],
      selectedOrganizationId: null,
      selectedReviewStatus: null,
      pagination: {
        page: 1,
        pageCount: 0,
        itemsPerPage: 10,
      },
      missions: [],
      debounce: null,
      rewards: [],
      defaultEditItem: { campaignBudgetId: null },
      editItem: {},
      openInviteDialog: false,
      permissionRole: null,
      permissionRoles: [
        {
          value: "admin",
          title: "管理員",
        },
        {
          value: "viewer",
          title: "一般員工",
        },
      ],
      inviteUrl: null,
      isLoadingMissionTypes: false,
      availableMissionTypes: [],
      isCampaignTypesLoaded: false,
      availableCampaignTypes: [],
      isLoadingRewardTypes: false,
      availableRewardTypes: [],
      tableRewardHeaders: [
        {
          text: "ID",
          sortable: false,
          value: "id",
        },
        {
          text: "類型",
          sortable: false,
          value: "type",
        },
        {
          text: "獎勵名稱",
          sortable: false,
          value: "rewardName",
        },
        {
          text: "可重複領取",
          sortable: false,
          align: "center",
          value: "multiCollectible",
        },
        {
          text: "獎勵內容",
          sortable: false,
          value: "context",
        },
        {
          text: "獎勵名額",
          sortable: false,
          //value: "inStock",
          value: "totalStock",
        },
        {
          text: "已發放名額",
          sortable: false,
          value: "rewardCounts",
        },
        {
          text: "建立時間",
          sortable: false,
          value: "createdAt",
        },
      ],
      tableMissionHeaders: [
        {
          text: "ID",
          sortable: false,
          value: "id",
        },
        {
          text: "類型",
          sortable: false,
          value: "type",
        },
        {
          text: "關卡名稱",
          sortable: false,
          value: "missionName",
        },
        {
          text: "重複參加",
          sortable: false,
          value: "repetitionMode",
        },
        {
          text: "描述",
          sortable: false,
          value: "description",
        },
        {
          text: "建立時間",
          sortable: false,
          value: "createdAt",
        },
      ],
      campaignTypeDialog: false,
    };
  },
  computed: {
    ...mapState({
      isLoggedIn: (state) => state.isLoggedIn,
      ssoUser: (state) => state.ssoUser,
      pointSettings: (state) => state.pointSettings,
      permissions: (state) => state.userPermissions,
      permissionScope: (state) => state.permissionScope,
    }),
    isActionDisabled() {
      return !this.isLoaded;
    },
    merchantId() {
      return this.isLoggedIn ? this.ssoUser.user._json.groups.merchant : null;
    },
    organizationId() {
      return this.isLoggedIn
        ? this.ssoUser.user._json.groups.organization
        : null;
    },
    departmentId() {
      return this.isLoggedIn ? this.ssoUser.user._json.groups.department : null;
    },
    canEdit() {
      if (this.permissions && this.permissions.length) {
        return checkPermission(
          this.permissions,
          "Admin:CampaignManage",
          "EditCampaigns",
          true
        );
      }
      return false;
    },
    canAudit() {
      if (this.permissions && this.permissions.length) {
        return checkPermission(
          this.permissions,
          "Admin:CampaignManage",
          "AuditCampaigns",
          true
        );
      }
      return false;
    },
    canEditManager() {
      if (this.permissions && this.permissions.length) {
        return checkPermission(
          this.permissions,
          "Admin:CampaignManage",
          "EditCampaignsManagers",
          true
        );
      }
      return false;
    },
    expandedCampaign() {
      return this.expanded.length ? this.expanded[0] : {};
    },
    missionRewards() {
      console.log("missionRewardRules:", this.missions, this.rewards);
      if (this.missions && this.rewards) {
        const rewards = [...this.rewards];
        this.missions.forEach((m) => {
          m.rewardRules.forEach((r) => {
            r.rewards.forEach((r2) => {
              const index = rewards.findIndex((reward) => {
                return reward.id === r2.rewardId;
              });
              if (index > -1) {
                rewards[index]["multiCollectible"] = r2.multiCollectible
                  ? r2.multiCollectible
                  : false;
              }
            });
          });
        });
        return rewards;
      }
      return [];
    },
    canEditAdvancedCampaign() {
      if (this.permissions && this.permissions.length) {
        return checkPermission(
          this.permissions,
          "Admin:CampaignManage",
          "EditAdvancedCampaigns",
          true
        );
      }
      return false;
    },
    filteredCampaignTypes() {
      return this.availableCampaignTypes.length
        ? this.availableCampaignTypes.filter((c) => {
            if (this.canEditAdvancedCampaign) {
              return c;
            } else {
              return !c.isAdvanced;
            }
          })
        : [];
    },
  },
  watch: {
    // pagination: {
    //   handler() {
    //     this.getDataFromApi();
    //   },
    //   deep: true,
    // },
    search: {
      handler() {
        clearTimeout(this.debounce);
        let self = this;
        this.debounce = setTimeout(function () {
          self.getDataFromApi();
        }, 200);
      },
    },
  },
  created() {
    this.unwatchIsLoggedIn = this.$watch("isLoggedIn", (newVal) => {
      if (newVal) {
        this.unwatchIsLoggedIn();
        this.init();
      }
    });
    if (process.env.VUE_APP_NODE_ENV === "production") {
      this.tableHeaders = this.tableHeaders.filter(
        (e) => e.value !== "participants"
      );
    }
  },
  mounted() {
    if (this.isLoggedIn) {
      this.init();
    }
    this.years = generateArrayOfYears();
    this.months = generateArrayOfMonths();
  },
  methods: {
    init() {
      this.getOrganizations();
      this.selectedOrganizationId = this.$route.query.organizationId
        ? parseInt(this.$route.query.organizationId)
        : this.organizationId;
      if (!this.permissionScope.department) {
        this.selectedDepartmentId = this.departmentId;
      }
      this.getDepartmentList();
      this.getCampaignTypes();
      this.getMissionTypes();
      this.getRewardTypes();
      // this.getDataFromApi();
    },

    onCloseEditDialog() {
      this.openEditDialog = false;
      // 重取一次campaign
      //this.getDataFromApi();
    },
    onCampaignUpdated() {
      this.getDataFromApi();
    },
    getOrganizations() {
      fetchOrganizations(this.merchantId)
        .then((res) => {
          console.log("fetchOrganizations done", res);
          this.availableOrganizations = [...res.data];
          console.log(
            "this.availableOrganizations:" +
              JSON.stringify(this.availableOrganizations)
          );
        })
        .catch((e) => {
          console.log("fetchOrganizations failed", e);
        });
    },
    getDepartmentList() {
      // const merchantId = 1;
      let query = {
        organizationId: this.selectedOrganizationId || this.organizationId,
      };
      console.log("getDepartmentList:" + JSON.stringify(query));

      // let matchRouteQuery = false;
      fetchDepartments(this.merchantId, query)
        .then((res) => {
          console.log("fetchDepartments done", res);
          this.departments = [...res.data];
          // if (
          //   this.departments &&
          //   this.departments.length &&
          //   this.$route.query.departmentId
          // ) {
          //   const result = this.departments.filter(
          //     (item) => item.id === parseInt(this.$route.query.departmentId)
          //   );
          //   matchRouteQuery = result.length ? true : false;
          // }
          // this.selectedDepartmentId = this.departments.length
          //   ? matchRouteQuery
          //     ? parseInt(this.$route.query.departmentId)
          //     : null
          //   : null;
        })
        .catch((e) => {
          console.log("fetchDepartments failed", e);
          this.$swal({
            text: "無法取得單位資料",
            type: "error",
            showCancelButton: false,
            confirmButtonText: this.$i18n.t(`common["Confirm"]`),
            customClass: {
              confirmButton: "btn bg-gradient-success",
              cancelButton: "btn bg-gradient-danger",
            },
          });
        });
    },
    // getCampaigns() {
    //   if (this.selectedMonth && !this.selectedYear) {
    //     this.$swal({
    //       text: "請選擇年份",
    //       type: "error",
    //       showCancelButton: false,
    //       confirmButtonText: this.$i18n.t(`common["Confirm"]`),
    //       customClass: {
    //         confirmButton: "btn bg-gradient-success",
    //         cancelButton: "btn bg-gradient-danger",
    //       },
    //     });
    //     return;
    //   }
    //   this.isLoaded = false;
    //   let query = {
    //     department: this.selectedDepartmentId,
    //     year: this.selectedYear,
    //     month: this.selectedMonth,
    //   };
    //   fetchCampaigns(this.merchantId, query)
    //     .then((res) => {
    //       console.log("fetchCampaigns done", res);
    //       this.tableData = [...res.data.campaigns];
    //     })
    //     .catch((e) => {
    //       console.log("fetchCampaigns failed", e);
    //     })
    //     .finally(() => {
    //       this.isLoaded = true;
    //     });
    // },
    getDataFromApi() {
      if (this.selectedMonth && !this.selectedYear) {
        this.$swal({
          text: "請選擇年份",
          type: "error",
          showCancelButton: false,
          confirmButtonText: this.$i18n.t(`common["Confirm"]`),
          customClass: {
            confirmButton: "btn bg-gradient-success",
            cancelButton: "btn bg-gradient-danger",
          },
        });
        return;
      }
      this.isLoaded = false;
      // reset expanded
      this.expanded = [];
      // this.pagination.pageCount = 0;
      // this.$vuetify.goTo(this.refs.campaignTable);
      this.getCampaigns()
        .then((data) => {
          this.tableData = [...data.items];
          this.tableData.map((i) => (i.originalStatus = i.reviewStatus));
          this.total = data.total;
          this.isLoaded = true;
          // this.$vuetify.goTo(this.refs.campaignTable);
        })
        .finally(() => {
          this.isLoaded = true;
        });
    },
    getCampaigns() {
      this.tableData = [];

      return new Promise((resolve, reject) => {
        const { sortBy, sortDesc, page, itemsPerPage } = this.pagination;
        console.log("pagination", this.pagination);
        let startTs = null;
        let endTs = null;
        if (this.selectedYear && this.selectedMonth) {
          startTs = moment(
            `${this.selectedYear}-${this.selectedMonth}-01`
          ).unix();
          endTs = moment(`${this.selectedYear}-${this.selectedMonth}-01`)
            .add(1, "months")
            .unix();
        } else if (this.selectedYear && !this.selectedMonth) {
          startTs = moment(`${this.selectedYear}-01-01`).unix();
          endTs = moment(`${this.selectedYear}-01-01`).add(1, "years").unix();
        }
        let query = {
          departmentId: this.selectedDepartmentId,
          organizationId: this.selectedOrganizationId,
          reviewStatus: this.selectedReviewStatus,
          startTs: startTs,
          endTs: endTs,
          offset: (page - 1) * itemsPerPage || 0,
          limit: itemsPerPage,
          sortBy: sortBy && sortBy.length ? sortBy[0] : null,
          desc: sortDesc,
          search: this.search,
        };
        let items = [];
        let total = 0;
        // sortby/search 參數是否要丟後端處理
        fetchCampaigns(this.merchantId, query)
          .then((res) => {
            // console.log("fetchCampaigns done:" + JSON.stringify(res));
            items = res.data.campaigns.map((campaign) => {
              let parsedType = this.availableCampaignTypes.find(
                (t) => t.type === campaign.prototype
              );
              if (parsedType) {
                campaign.prototypeName = parsedType.name;
                campaign.isAdvanced = parsedType.isAdvanced;
              } else {
                campaign.prototypeName = "";
                campaign.isAdvanced = 0;
              }
              campaign.reviewStatus =
                campaign.campaignStatus === "approved" ||
                campaign.campaignStatus === "rejected" ||
                campaign.campaignStatus === "draft"
                  ? campaign.campaignStatus
                  : "submit";
              return campaign;
            });
            // items = [...res.data.campaigns];
            // items.forEach((i) => {
            //   i.reviewStatus =
            //     i.campaignStatus === "approved" ||
            //     i.campaignStatus === "rejected" ||
            //     i.campaignStatus === "draft"
            //       ? i.campaignStatus
            //       : "submit";
            // });
            total = res.data.total;
          })
          .catch((e) => {
            console.log("fetchCampaigns failed", e);
            this.$swal({
              text: "無法取得任務資料",
              type: "error",
              showCancelButton: false,
              confirmButtonText: this.$i18n.t(`common["Confirm"]`),
              customClass: {
                confirmButton: "btn bg-gradient-success",
                cancelButton: "btn bg-gradient-danger",
              },
            });
          })
          .finally(() => {
            // if (sortBy.length === 1 && sortDesc.length === 1) {
            //   items = items.sort((a, b) => {
            //     const sortA = a[sortBy[0]];
            //     const sortB = b[sortBy[0]];

            //     if (sortDesc[0]) {
            //       if (sortA < sortB) return 1;
            //       if (sortA > sortB) return -1;
            //       return 0;
            //     } else {
            //       if (sortA < sortB) return -1;
            //       if (sortA > sortB) return 1;
            //       return 0;
            //     }
            //   });
            // }
            setTimeout(() => {
              resolve({
                items,
                total,
              });
            }, 1000);
          });
      });
    },
    loadDetails({ item, value }) {
      // get mission
      if (value) {
        this.getCampaignMissions(item.id);
        this.getCampaignRewards(item.id);
      }
    },
    getCampaignMissions(campaignId) {
      console.log("getCampaignMissions");
      this.missions = [];
      fetchCampaignMissions(this.merchantId, campaignId, null)
        .then((res) => {
          console.log("fetchCampaignMissions done", res);
          this.missions = [...res.data];
        })
        .catch((e) => {
          this.missions = [];
          console.log("fetchCampaignMissions failed", e);
          this.$swal({
            text: "無法取得任務資料",
            type: "error",
            showCancelButton: false,
            confirmButtonText: this.$i18n.t(`common["Confirm"]`),
            customClass: {
              confirmButton: "btn bg-gradient-success",
              cancelButton: "btn bg-gradient-danger",
            },
          });
        });
    },
    getCampaignRewards(campaignId) {
      this.rewards = [];
      fetchCampaignRewards(this.merchantId, campaignId, null)
        .then((res) => {
          console.log("fetchCampaignRewards done", res);
          this.rewards = [...res.data];
        })
        .catch((e) => {
          this.rewards = [];
          console.log("fetchCampaignRewards failed", e);
          this.$swal({
            text: "無法取得獎勵資料",
            type: "error",
            showCancelButton: false,
            confirmButtonText: this.$i18n.t(`common["Confirm"]`),
            customClass: {
              confirmButton: "btn bg-gradient-success",
              cancelButton: "btn bg-gradient-danger",
            },
          });
        });
    },
    onEditItem(item, campaignType) {
      let start = null;
      let end = null;
      if (item) {
        this.editItem = Object.assign({}, item);
        start = moment.unix(item.startTime);
        end = moment.unix(item.endTime);
        this.isNew = false;
      } else {
        this.editItem = Object.assign({}, this.defaultEditItem);
        start = moment().add(1, "days");
        end = moment().add(1, "months").add(1, "days");
        this.editItem.prototype = campaignType;
        this.editItem.organizationId = this.organizationId;
        this.editItem.departmentId = this.departmentId;
        this.isNew = true;
      }
      this.editItem.parsedStartTime = {
        date: start.format("YYYY-MM-DD"),
        hour: this.isNew ? 0 : start.hour(),
        minute: this.isNew ? 0 : start.minutes(),
        seconds: this.isNew ? 0 : start.seconds(),
      };
      this.editItem.parsedEndTime = {
        date: end.format("YYYY-MM-DD"),
        hour: this.isNew ? 0 : end.hour(),
        minute: this.isNew ? 0 : end.minutes(),
        seconds: this.isNew ? 0 : end.seconds(),
      };
      console.log("onEditCampaign:" + JSON.stringify(this.editItem));

      // reset expanded
      this.expanded = [];

      this.openEditDialog = true;
    },
    showErrorAlert(message, inputTitle) {
      let title = this.$i18n.t(`errors["Update"]`);
      if (this.isNew) {
        title = this.$i18n.t(`errors["Create"]`);
      }
      this.$swal({
        title: inputTitle || title,
        html: message,
        type: "error",
        showCancelButton: false,
        confirmButtonText: this.$i18n.t(`common["Confirm"]`),
        customClass: {
          confirmButton: "btn bg-gradient-success",
          cancelButton: "btn bg-gradient-danger",
        },
      });
    },
    onDeleteCampaignClick(item) {
      this.$swal({
        title: `刪除任務`,
        html: `是否確定刪除「<b>${item.campaignName}</b>」？
          <br/><br/> 刪除後無法恢復`,
        type: "info",
        showCancelButton: true,
        confirmButtonText: this.$i18n.t(`common["Confirm"]`),
        cancelButtonText: this.$i18n.t(`common["Cancel"]`),
        customClass: {
          confirmButton: "btn bg-gradient-success",
          cancelButton: "btn bg-gradient-danger",
        },
      }).then((res) => {
        console.log(res);
        if (res.isConfirmed) {
          this.onDeleteCampaign(item);
        }
      });
    },
    onDeleteCampaign(item) {
      deleteCampaign(this.merchantId, item.id)
        .then((res) => {
          console.log("deleteCampaign done: ", res);
          this.$swal({
            title: "刪除成功",
            html: `已成功刪除任務<br/> <b>${item.campaignName}</b>`,
            type: "sccess",
            showCancelButton: false,
            confirmButtonText: this.$i18n.t(`common["Confirm"]`),
            customClass: {
              confirmButton: "btn bg-gradient-success",
              cancelButton: "btn bg-gradient-danger",
            },
          });
          this.getDataFromApi();
        })
        .catch((e) => {
          console.log("deleteCampaign failed", e);
          let errmsg = `無法刪除任務「<b>${item.campaignName}</b>」`;
          if (e.response && e.response.data && e.response.data.message) {
            errmsg += "<br/>" + e.response.data.message;
          }
          const title = "刪除失敗";
          this.showErrorAlert(errmsg, title);
        });
    },
    onClickCampaignStatusChange(item) {
      let status = "";
      switch (item.reviewStatus) {
        case "approved":
          status = "審核通過";
          break;
        case "rejected":
          status = "審核駁回";
          break;
        case "draft":
          status = "草稿";
          break;
        default:
          status = "送審中";
          break;
      }
      this.$swal({
        title: `更新審核狀態`,
        html: `是否確定更新「<b>${item.campaignName}</b>」的審核狀態？
          <br/> 更新後審核狀態為：「<b>${status}</b>」`,
        type: "info",
        showCancelButton: true,
        confirmButtonText: this.$i18n.t(`common["Confirm"]`),
        cancelButtonText: this.$i18n.t(`common["Cancel"]`),
        customClass: {
          confirmButton: "btn bg-gradient-success",
          cancelButton: "btn bg-gradient-danger",
        },
      }).then((res) => {
        if (res.isConfirmed && item.reviewStatus !== "draft") {
          this.onCampaignStatusChange(item);
        } else if (!res.isConfirmed) {
          // this.getDataFromApi();
          item.reviewStatus = item.originalStatus;
        }
      });
    },
    onCampaignStatusChange(item) {
      let reviewCampaign = submitCampaign;
      switch (item.reviewStatus) {
        case "approved":
          reviewCampaign = approveCampaign;
          break;
        case "rejected":
          reviewCampaign = rejectCampaign;
          break;
        default:
          break;
      }
      reviewCampaign(this.merchantId, item.id)
        .then((res) => {
          console.log("review campaign done: ", res);
          this.$swal({
            title: "更新成功",
            html: `已成功更新任務「<b>${item.campaignName}</b>」的審核狀態`,
            type: "sccess",
            showCancelButton: false,
            confirmButtonText: this.$i18n.t(`common["Confirm"]`),
            customClass: {
              confirmButton: "btn bg-gradient-success",
              cancelButton: "btn bg-gradient-danger",
            },
          });
          this.getDataFromApi();
        })
        .catch((e) => {
          console.log("review campaign failed", e);
          let errmsg = `無法更新任務「<b>${item.campaignName}</b>」的審核狀態`;
          if (e.response && e.response.data && e.response.data.message) {
            errmsg += "<br/>" + e.response.data.message;
          }
          const title = "更新失敗";
          this.showErrorAlert(errmsg, title);
        });
    },
    onOpenParticipantDialog(item) {
      this.selectedCampaign = item;
      this.openParticipantDialog = true;
    },
    onCloseParticipantDialog() {
      this.selectedCampaign = {};
      this.openParticipantDialog = false;
    },
    onInviteClick(item) {
      this.selectedCampaign = item;
      this.openInviteDialog = true;
    },
    generateInviteUrl() {
      let data = {
        permissionRole: [this.permissionRole],
      };
      generateCampaignManagerInviteUrl(
        this.merchantId,
        this.selectedCampaign.id,
        data
      )
        .then((res) => {
          console.log("generateInviteUrl done", res);
          this.inviteUrl = res.data.url;
        })
        .catch((e) => {
          console.log("Fail to get invite url:", e);
          this.$swal({
            text: "無法取得邀請連結",
            type: "error",
            showCancelButton: false,
            confirmButtonText: this.$i18n.t(`common["Confirm"]`),
            customClass: {
              confirmButton: "btn bg-gradient-success",
              cancelButton: "btn bg-gradient-danger",
            },
          });
        });
    },
    closeInviteDialog() {
      this.selectedCampaign = null;
      this.permissionRole = null;
      this.inviteUrl = null;
      this.openInviteDialog = false;
    },
    copyInviteUrl() {
      const text = this.inviteUrl;
      navigator.clipboard.writeText(text).then(
        function () {
          console.log("Async: Copying to clipboard was successful!");
        },
        function (err) {
          console.error("Async: Could not copy text: ", err);
        }
      );
    },
    getMissionTypes() {
      this.isLoadingMissionTypes = true;
      let query = {};
      fetchMissionTypes(this.merchantId, query)
        .then((res) => {
          console.log("fetchMissionTypes done", res);
          this.availableMissionTypes = [...res.data];
        })
        .catch((e) => {
          console.log("fetchMissionTypes failed", e);
        })
        .finally(() => {
          this.isLoadingMissionTypes = false;
        });
    },
    getMissionType(type) {
      let parsedType = this.availableMissionTypes.find((t) => t.type === type);
      return parsedType ? parsedType.name : "Unknown";
    },
    getCampaignType(type) {
      let parsedType = this.availableCampaignTypes.find((t) => t.type === type);
      return parsedType ? parsedType.name : "";
    },
    getCampaignTypes() {
      this.isCampaignTypesLoaded = false;
      let query = {};
      fetchCampaignTypes(this.merchantId, query)
        .then((res) => {
          console.log("fetchCampaignTypes done", res);
          this.availableCampaignTypes = [...res.data];
        })
        .catch((e) => {
          console.log("fetchMissionTypes failed", e);
        })
        .finally(() => {
          this.isCampaignTypesLoaded = true;
          this.getDataFromApi();
        });
    },
    getRewardTypes() {
      this.isLoadingRewardTypes = true;
      fetchRewardTypes(this.merchantId, null)
        .then((res) => {
          console.log("fetchRewardTypes done", res);
          this.availableRewardTypes = [...res.data];
        })
        .catch((e) => {
          console.log("fetchRewardTypes failed", e);
        })
        .finally(() => {
          this.isLoadingRewardTypes = false;
        });
    },
    getRewardType(type) {
      let parsedType = this.availableRewardTypes.find((t) => t.type === type);
      return parsedType ? parsedType.name : "Unknown";
    },
    exportEntryCode(missionId) {
      console.log("export entry code :" + this.expandedCampaign.id);
      exportCampaignEntryCode(
        this.merchantId,
        this.expandedCampaign.id,
        missionId,
        // "M_zkeWEKBM5y",
        // 42,
        null
      )
        .then((res) => {
          console.log("exportCampaignEntryCode done", res);
          if (res && res.data) {
            const blob = s2ab(res.data);
            var fileURL = window.URL.createObjectURL(new Blob([blob]), {
              type: "application/octer-stream",
            });
            var fileLink = document.createElement("a");

            fileLink.href = fileURL;
            fileLink.setAttribute(
              "download",
              `${
                this.expandedCampaign.campaignName
              }-活動代碼-${new Date().getTime()}.xlsx`
            );
            document.body.appendChild(fileLink);

            fileLink.click();
          } else {
            throw new Error("No Data");
          }
        })
        .catch((e) => {
          console.log("exportCampaignEntryCode failed", e);
          let errmsg = "無法匯出活動代碼，請稍後重試";
          if (e.response && e.response.data && e.response.data.message) {
            errmsg += "<br/>" + e.response.data.message;
          }
          this.showErrorAlert(errmsg, "匯出失敗");
        })
        .finally(() => {
          this.isDownloading = false;
        });
    },
  },
};
</script>
<style scoped>
.text-wrap-whitespace {
  white-space: pre-wrap;
  /* word-wrap: break-word;
  overflow-wrap: break-word;
  word-break: break-word; */
}
</style>
