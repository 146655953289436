<template>
  <v-card class="shadow border-radius-xl my-10 mx-auto">
    <v-card-title>
      <v-spacer></v-spacer>
      <v-btn fab plain @click="close">
        <v-icon> fas fa-times</v-icon>
      </v-btn>
    </v-card-title>
    <v-stepper
      v-if="editItem"
      alt-labels
      elevation="0"
      v-model="curr"
      class="bg-transparent overflow-visible"
    >
      <v-row class="mx-auto">
        <v-col cols="12" class="mx-auto pt-1">
          <v-stepper-header
            class="
              bg-gradient-primary
              shadow-primary
              border-radius-lg
              mx-4
              mt-n4
            "
          >
            <template v-for="(step, index) in steppers">
              <v-stepper-step
                color="#344767"
                :key="`${index}-step`"
                :step="index + 1"
              >
                <span
                  class="
                    text-white
                    opacity-7
                    text-xs
                    font-weight-normal
                    text-uppercase
                  "
                >
                  {{ step.name }}
                </span>
              </v-stepper-step>

              <v-divider
                v-if="index + 1 !== steppers.length"
                :key="index"
              ></v-divider>
            </template>
          </v-stepper-header>
        </v-col>
      </v-row>

      <v-row class="mx-auto">
        <v-col cols="12">
          <v-stepper-items class="border-radius-xl overflow-hidden mt-5">
            <v-stepper-content
              v-for="(step, n) in steppers"
              :step="n + 1"
              :key="n"
              class="bg-white border-radius-xl px-4 pt-4"
            >
              <v-card v-if="n === 0 && editItem">
                <v-card-text>
                  <v-form
                    :ref="'stepForm'"
                    v-model="step.valid"
                    lazy-validation
                  >
                    <div>
                      <v-row class="text-center">
                        <v-col cols="12" class="mx-auto">
                          <h5 class="text-h5 text-typo font-weight-normal mb-2">
                            {{
                              isNew
                                ? $t(`common["Add"]`)
                                : $t(`common["Edit"]`)
                            }}任務 - {{ currentCampaignPrototype }}
                          </h5>
                          <p class="text-body font-weight-light">
                            請填寫任務基本設定，按下一步時，將自動儲存設定
                          </p>
                        </v-col>
                      </v-row>
                      <!-- <v-row class="mt-2">
                        <v-col sm="8" cols="12" class="mx-auto">
                          <h5 class="text-h5 text-typo font-weight-normal">
                            基本資訊
                          </h5>
                        </v-col>
                      </v-row> -->
                      <v-row class="mt-2">
                        <v-col sm="4" cols="12" offset-sm="2">
                          <v-select
                            v-model="editItem.prototype"
                            label="任務類型"
                            :items="availableCampaignTypes"
                            item-text="name"
                            item-value="type"
                            class="font-size-input-lg input-style"
                            clearable
                            :disabled="true"
                          >
                          </v-select>
                        </v-col>
                        <v-col sm="4" cols="12">
                          <v-select
                            v-model="editItem.visibility"
                            label="可見度"
                            :items="availableVisibilityOptions"
                            item-text="name"
                            item-value="value"
                            class="font-size-input-lg input-style"
                            :disabled="
                              isActionDisabled || !canEditAdvancedCampaign
                            "
                          >
                          </v-select>
                        </v-col>
                        <v-col sm="4" cols="12" offset-sm="2">
                          <v-text-field
                            v-model="editItem.campaignName"
                            label="任務名稱"
                            color="#e91e63"
                            class="font-size-input-lg input-style"
                            :rules="rules.required"
                          ></v-text-field>
                        </v-col>
                        <v-col sm="4" cols="12">
                          <v-select
                            v-model="editItem.publisherType"
                            label="上架類型"
                            :items="availablePublisherTypes"
                            item-text="name"
                            item-value="value"
                            class="font-size-input-lg input-style"
                            :disabled="isActionDisabled"
                          >
                          </v-select>
                        </v-col>
                        <v-col col="12" sm="8" offset-sm="2">
                          <v-textarea
                            v-model="editItem.description"
                            outlined
                            label="任務描述"
                            class="font-size-input-lg input-style"
                          ></v-textarea>
                        </v-col>
                        <!-- <v-col sm="4" cols="12">
                          <v-text-field
                            v-model="editItem.description"
                            label="任務描述"
                            color="#e91e63"
                            class="font-size-input-lg input-style"
                          ></v-text-field>
                        </v-col> -->
                        <v-col sm="4" cols="12" offset-sm="2">
                          <v-row>
                            <v-col cols="12" lg="4">
                              <!-- <v-text-field
                                label="開始日期"
                                color="#e91e63"
                                required
                                class="font-size-input-lg input-style"
                              ></v-text-field> -->
                              <v-menu
                                v-model="showPicker1"
                                :close-on-content-click="false"
                                transition="scale-transition"
                                offset-y
                                max-width="290px"
                                min-width="290px"
                                name="startDate"
                              >
                                <template v-slot:activator="{ on }">
                                  <v-text-field
                                    v-model="editItem.parsedStartTime.date"
                                    label="開始日期"
                                    readonly
                                    v-on="on"
                                  ></v-text-field>
                                </template>
                                <v-date-picker
                                  v-model="editItem.parsedStartTime.date"
                                  color="blue-grey lighten-1"
                                  @input="showPicker1 = false"
                                ></v-date-picker>
                              </v-menu>
                            </v-col>
                            <v-col cols="4" lg="2">
                              <v-select
                                v-model="editItem.parsedStartTime.hour"
                                label="時"
                                :items="availableHours"
                                item-text="name"
                                item-value="value"
                                :menu-props="{ closeOnContentClick: true }"
                                :disabled="isActionDisabled"
                              >
                              </v-select>
                            </v-col>
                            <v-col cols="4" lg="2">
                              <v-select
                                v-model="editItem.parsedStartTime.minute"
                                label="分"
                                :items="availableMinutes"
                                item-text="name"
                                item-value="value"
                                :menu-props="{ closeOnContentClick: true }"
                                :disabled="isActionDisabled"
                              >
                              </v-select>
                            </v-col>
                            <v-col cols="4" lg="2">
                              <v-select
                                v-model="editItem.parsedStartTime.seconds"
                                label="秒"
                                :items="availableMinutes"
                                item-text="name"
                                item-value="value"
                                :menu-props="{ closeOnContentClick: true }"
                                :disabled="isActionDisabled"
                              >
                              </v-select>
                            </v-col>
                          </v-row>
                        </v-col>
                        <v-col sm="4" cols="12">
                          <v-row>
                            <v-col cols="12" lg="4">
                              <!-- <v-text-field
                                label="結束日期"
                                color="#e91e63"
                                required
                                class="font-size-input-lg input-style"
                              ></v-text-field> -->
                              <v-menu
                                v-model="showPicker2"
                                :close-on-content-click="false"
                                transition="scale-transition"
                                offset-y
                                max-width="290px"
                                min-width="290px"
                              >
                                <template v-slot:activator="{ on }">
                                  <v-text-field
                                    v-model="editItem.parsedEndTime.date"
                                    label="結束日期"
                                    readonly
                                    v-on="on"
                                  ></v-text-field>
                                </template>
                                <v-date-picker
                                  v-model="editItem.parsedEndTime.date"
                                  color="blue-grey lighten-1"
                                  @input="showPicker2 = false"
                                ></v-date-picker>
                              </v-menu>
                            </v-col>
                            <v-col cols="4" lg="2">
                              <v-select
                                v-model="editItem.parsedEndTime.hour"
                                label="時"
                                :items="availableHours"
                                item-text="name"
                                item-value="value"
                                :menu-props="{ closeOnContentClick: true }"
                                :disabled="isActionDisabled"
                              >
                              </v-select>
                            </v-col>
                            <v-col cols="4" lg="2">
                              <v-select
                                v-model="editItem.parsedEndTime.minute"
                                label="分"
                                :items="availableMinutes"
                                item-text="name"
                                item-value="value"
                                :menu-props="{ closeOnContentClick: true }"
                                :disabled="isActionDisabled"
                              >
                              </v-select>
                            </v-col>
                            <v-col cols="4" lg="2">
                              <v-select
                                v-model="editItem.parsedEndTime.seconds"
                                label="秒"
                                :items="availableMinutes"
                                item-text="name"
                                item-value="value"
                                :menu-props="{ closeOnContentClick: true }"
                                :disabled="isActionDisabled"
                              >
                              </v-select>
                            </v-col>
                          </v-row>
                        </v-col>

                        <!-- <v-col sm="8" cols="12" class="mx-auto">
                          <v-row>
                            <v-col cols="6">
                              <v-row>
                                <v-col cols="12">
                                  <v-file-input
                                    accept="image/*"
                                    label="活動圖片"
                                    v-model="uploadImg"
                                    @change="onImageFileChange"
                                    :disabled="isActionDisabled"
                                  ></v-file-input>
                                </v-col>
                                <v-col
                                  v-if="uploadImgUrl || editItem.imageUrl"
                                  cols="12"
                                >
                                  <v-img
                                    v-if="uploadImgUrl"
                                    width="200"
                                    :src="uploadImgUrl"
                                  >
                                    <template v-slot:placeholder>
                                      <v-row
                                        class="fill-height ma-0"
                                        align="center"
                                        justify="center"
                                      >
                                        <v-progress-circular
                                          indeterminate
                                          color="black"
                                          :size="30"
                                        ></v-progress-circular>
                                      </v-row>
                                    </template>
                                  </v-img>
                                  <v-img
                                    v-else
                                    width="200"
                                    :src="editItem.imageUrl"
                                  >
                                    <template v-slot:placeholder>
                                      <v-row
                                        class="fill-height ma-0"
                                        align="center"
                                        justify="center"
                                      >
                                        <v-progress-circular
                                          indeterminate
                                          color="black"
                                          :size="30"
                                        ></v-progress-circular>
                                      </v-row>
                                    </template>
                                  </v-img>
                                </v-col>
                              </v-row>
                            </v-col>
                            <v-col cols="6">
                              <v-text-field
                                v-model="editItem.continueUrl"
                                label="跳轉連結"
                                color="#e91e63"
                                class="font-size-input-lg input-style"
                              ></v-text-field>
                            </v-col>
                          </v-row>
                        </v-col>
                        <v-col sm="8" cols="12" class="mx-auto">
                          <label
                            class="text-body text-sm font-weight-normal ms-1"
                          >
                            活動文案
                          </label>
                          <html-editor
                            :value="context"
                            name="articleEditor"
                            @input="onInputChange"
                            :disabled="isActionDisabled"
                          ></html-editor>
                        </v-col> -->
                        <v-col sm="8" cols="12" class="mx-auto">
                          <v-checkbox
                            v-model="usePointBudget"
                            color="#141727"
                            class="large-checkbox"
                            hide-details
                            label="使用點數預算"
                            :disabled="isActionDisabled || isReviewed"
                            @change="onToggleUseBudget"
                          >
                            <!-- <template v-slot:label>
                              <span class="text-body text-sm ls-0">
                                使用點數預算
                              </span>
                            </template> -->
                          </v-checkbox>
                        </v-col>
                      </v-row>
                      <v-row v-if="usePointBudget">
                        <v-col sm="4" cols="12" offset-sm="2">
                          <v-select
                            v-model="selectedPointId"
                            :items="availablePoints"
                            item-text="name"
                            item-value="id"
                            :menu-props="{ closeOnContentClick: true }"
                            :label="$t(`common['Select Point Setting']`)"
                            :disabled="isActionDisabled || isReviewed"
                            @change="onPointSettingChange"
                            :no-data-text="$t(`common['No Data']`)"
                          >
                          </v-select>
                        </v-col>
                        <v-col sm="4" cols="12">
                          <v-select
                            v-model="selectedPeriodId"
                            label="預算期別"
                            :items="availableUnclosingPeriods"
                            item-text="name"
                            item-value="id"
                            :menu-props="{ closeOnContentClick: true }"
                            :disabled="isActionDisabled || isReviewed"
                            @change="getCampaignBudgets"
                            :no-data-text="$t(`common['No Data']`)"
                          >
                          </v-select>
                        </v-col>
                        <v-col sm="4" cols="12" offset-sm="2">
                          <v-select
                            v-model="selectedOrganizationId"
                            :label="$t(`budgets['Organization']`)"
                            :items="availableOrganizations"
                            item-text="organizationName"
                            item-value="organizationId"
                            :rules="rules.required"
                            @change="
                              getDepartmentList();
                              selectedDepartmentId = null;
                              editItem.campaignBudgetId = null;
                              availableBudgets = [];
                            "
                            :no-data-text="$t(`common['No Data']`)"
                            :disabled="!permissionScope.organization"
                          ></v-select>
                        </v-col>
                        <v-col sm="4" cols="12">
                          <v-select
                            v-model="selectedDepartmentId"
                            label="預算單位"
                            :items="availableDepartments"
                            item-text="departmentName"
                            item-value="id"
                            :menu-props="{ closeOnContentClick: true }"
                            :disabled="
                              !permissionScope.department ||
                              isActionDisabled ||
                              isReviewed
                            "
                            @change="getCampaignBudgets()"
                            :no-data-text="$t(`common['No Data']`)"
                          >
                          </v-select>
                        </v-col>
                        <v-col sm="4" cols="12" offset-sm="2">
                          <v-select
                            v-model="editItem.campaignBudgetId"
                            label="活動預算"
                            :items="dAvailableBudgets"
                            item-text="budgetName"
                            item-value="id"
                            :menu-props="{ closeOnContentClick: true }"
                            :disabled="isActionDisabled || isReviewed"
                            :no-data-text="$t(`common['No Data']`)"
                            :rules="usePointBudget ? rules.required : []"
                          >
                            <template slot="selection" slot-scope="data">
                              {{ data.item.budgetName }} （{{
                                formatNumber(data.item.availablePoints, 0)
                              }}）
                            </template>
                            <template slot="item" slot-scope="data">
                              {{ data.item.budgetName }} （{{
                                formatNumber(data.item.availablePoints, 0)
                              }}）
                            </template>
                          </v-select>
                        </v-col>
                      </v-row>
                      <v-row v-else>
                        <v-col sm="4" cols="12" offset-sm="2">
                          <v-select
                            v-model="selectedOrganizationId"
                            :label="$t(`budgets['Organization']`)"
                            :items="availableOrganizations"
                            item-text="organizationName"
                            item-value="organizationId"
                            :rules="rules.required"
                            @change="
                              getDepartmentList();
                              selectedDepartmentId = null;
                              editItem.campaignBudgetId = null;
                              availableBudgets = [];
                            "
                            :no-data-text="$t(`common['No Data']`)"
                            :disabled="!permissionScope.organization"
                          ></v-select>
                        </v-col>
                        <v-col sm="4" cols="12">
                          <v-select
                            v-model="selectedDepartmentId"
                            label="任務所屬單位"
                            :items="availableDepartments"
                            item-text="departmentName"
                            item-value="id"
                            :menu-props="{ closeOnContentClick: true }"
                            :disabled="
                              !permissionScope.department ||
                              isActionDisabled ||
                              isReviewed
                            "
                            @change="getCampaignBudgets()"
                            :no-data-text="$t(`common['No Data']`)"
                          >
                          </v-select>
                        </v-col>
                      </v-row>
                    </div>
                  </v-form>
                </v-card-text>
              </v-card>
              <v-card v-else-if="n === 1">
                <v-card-text>
                  <v-form
                    :ref="'stepForm'"
                    v-model="step.valid"
                    lazy-validation
                  >
                    <div>
                      <v-row class="text-center">
                        <v-col cols="12" class="mx-auto">
                          <h5 class="text-h5 text-typo font-weight-normal mb-2">
                            獎勵設定
                          </h5>
                          <p class="text-body font-weight-light">
                            請填寫任務獎勵設定
                          </p>
                        </v-col>
                      </v-row>
                      <v-row class="text-center align-center mt-2">
                        <Rewards
                          v-if="n === 1 && currentCampaignId"
                          :points="dAvailablePoints"
                          :campaignId="currentCampaignId"
                          :useBudget="editItem.campaignBudgetId ? true : false"
                        >
                        </Rewards>
                      </v-row>
                    </div>
                  </v-form>
                </v-card-text>
              </v-card>
              <v-card v-else-if="n === 2">
                <v-card-text>
                  <v-form
                    :ref="'stepForm'"
                    v-model="step.valid"
                    lazy-validation
                  >
                    <div>
                      <v-row class="text-center">
                        <v-col cols="12" class="mx-auto">
                          <h5 class="text-h5 text-typo font-weight-normal mb-2">
                            關卡設定
                          </h5>
                          <p class="text-body font-weight-light">
                            請填寫任務關卡設定
                          </p>
                        </v-col>
                      </v-row>
                      <v-row class="mt-2">
                        <Missions
                          v-if="n === 2 && currentCampaignId"
                          :campaignId="currentCampaignId"
                          :campaignPrototype="editItem.prototype"
                        >
                        </Missions>
                      </v-row>
                    </div>
                  </v-form>
                </v-card-text>
              </v-card>
              <div class="text-end" v-if="n === 0">
                <v-btn
                  :elevation="0"
                  class="
                    font-weight-bold
                    text-xs
                    btn-default
                    bg-gradient-default
                    py-5
                    px-6
                    mt-2
                    mb-2
                    me-2
                  "
                  color="primary"
                  @click="validate(n)"
                >
                  {{ $t(`common['Next']`) }}
                </v-btn>
              </div>
              <div class="d-flex mt-10" v-else-if="n + 1 < steppers.length">
                <v-btn
                  :elevation="0"
                  class="
                    font-weight-bold
                    text-xs text-dark
                    shadow-none
                    bg-transparent
                    btn-outline-secondary
                    py-5
                    px-6
                    mt-6
                    mb-2
                    ms-2
                  "
                  @click="curr = n"
                >
                  {{ $t(`common['Prev']`) }}
                </v-btn>
                <v-btn
                  :elevation="0"
                  class="
                    font-weight-bold
                    text-xs
                    btn-default
                    bg-gradient-default
                    py-5
                    px-6
                    mt-6
                    mb-2
                    me-2
                    ms-auto
                  "
                  color="primary"
                  @click="validate(n)"
                >
                  {{ $t(`common['Next']`) }}
                </v-btn>
              </div>
              <div class="d-flex" v-else>
                <v-btn
                  :elevation="0"
                  class="
                    font-weight-bold
                    text-xs text-dark
                    btn-light
                    shadow-none
                    bg-transparent
                    btn-outline-secondary
                    py-5
                    px-6
                    mt-2
                    mb-2
                    ms-2
                  "
                  @click="curr = n"
                >
                  {{ $t(`common['Prev']`) }}
                </v-btn>
                <v-btn
                  :elevation="0"
                  class="
                    font-weight-bold
                    text-xs
                    btn-default
                    bg-gradient-default
                    py-5
                    px-6
                    mt-2
                    mb-2
                    me-2
                    ms-auto
                  "
                  color="primary"
                  @click="done"
                >
                  {{ $t(`common['Finish']`) }}
                </v-btn>
              </div>
            </v-stepper-content>
          </v-stepper-items>
        </v-col>
      </v-row>
    </v-stepper>
  </v-card>
</template>
<script>
import moment from "moment";
import { mapState } from "vuex";
import {
  fetchPointSettings,
  fetchPointPeriods,
  fetchDepartments,
  fetchCampaignBudgets,
  fetchOrganizations,
} from "src/apis/fetchData";
import { uploadImage } from "src/apis/updateData";
import { updateCampaign, fetchCampaignTypes } from "src/apis/campaignCore";
import {
  formatNumber,
  generateArrayOfHours,
  generateArrayOfMinutes,
  checkPermission,
} from "src/util/utils";
import HtmlEditor from "./HtmlEditor.vue";
import Rewards from "./Rewards.vue";
import Missions from "./Missions.vue";
import variables from "src/util/variables";

export default {
  name: "Wizard",
  props: {
    campaign: {
      type: Object,
      default: function () {
        return {};
      },
      required: false,
    },
    pointId: {
      type: Number,
      default: null,
      required: false,
    },
  },
  components: { HtmlEditor, Rewards, Missions },
  data() {
    return {
      moment: moment,
      formatNumber: formatNumber,
      currentCampaignId: null,
      // currentCampaign: null,
      showPicker1: false,
      showPicker2: false,
      startDate: null,
      endDate: null,
      usePointBudget: true,
      availableHours: [],
      availableMinutes: [],
      availablePoints: [],
      availablePeriods: [],
      availableDepartments: [],
      availableBudgets: [],
      availableOrganizations: [],
      selectedOrganizationId: null,
      selectedDepartmentId: null,
      selectedPointId: null,
      selectedPeriodId: null,
      // selectedBudgetId: null,
      context: null,
      uploadImg: null,
      uploadImgUrl: null,
      isPointSettingsLoaded: false,
      isPeriodsLoaded: false,
      isDepartmentLoaded: false,
      steppers: [
        {
          index: 1,
          name: "Campaign/任務",
          valid: false,
        },
        {
          index: 2,
          name: "Reward/獎勵",
          valid: false,
        },
        {
          index: 3,
          name: "Mission/關卡",
          valid: false,
        },
      ],
      curr: 1,
      rules: {
        required: [(v) => !!v || this.$t(`common["Required"]`)],
      },
      lastEditItem: "",
      editItem: null,
      isNew: false,
      availableCampaignTypes: [],
      defaultMunicipal: ["qna", "questionnaire", "vote", "check-in"],
    };
  },
  computed: {
    ...mapState({
      isLoggedIn: (state) => state.isLoggedIn,
      ssoUser: (state) => state.ssoUser,
      permissions: (state) => state.userPermissions,
      permissionScope: (state) => state.permissionScope,
    }),
    isActionDisabled() {
      return (
        !(
          this.isDepartmentLoaded &&
          this.isPointSettingsLoaded &&
          this.isPeriodsLoaded
        ) && !this.isLoadingCampaignTypes
      );
    },
    merchantId() {
      return this.isLoggedIn ? this.ssoUser.user._json.groups.merchant : null;
    },
    organizationId() {
      return this.isLoggedIn
        ? this.ssoUser.user._json.groups.organization
        : null;
    },
    departmentId() {
      return this.isLoggedIn ? this.ssoUser.user._json.groups.department : null;
    },
    isReviewed() {
      return (
        this.editItem.reviewStatus === "rejected" ||
        this.editItem.reviewStatus === "approved"
      );
    },
    availableUnclosingPeriods() {
      return this.availablePeriods.filter((p) => p.closeStatus === 0);
    },
    availableVisibilityOptions() {
      return variables.missionVisibiltyOptions;
    },
    availablePublisherTypes() {
      return variables.publisherTypes;
    },
    currentCampaignPrototype() {
      if (this.availableCampaignTypes && this.editItem) {
        return this.getCampaignType(this.editItem.prototype);
      } else {
        return "";
      }
    },
    dAvailableBudgets() {
      let arrayBudget = [];
      if (this.availableBudgets.length) {
        arrayBudget = this.availableBudgets.filter((item) => {
          return item.reviewStatus === 1 && item.closeStatus === 0;
        });
      }
      return arrayBudget;
    },
    dAvailablePoints() {
      let points = [...this.availablePoints];
      if (this.usePointBudget) {
        return points.filter((point) => point.id === this.selectedPointId);
      }
      return points;
    },
    canEditAdvancedCampaign() {
      if (this.permissions && this.permissions.length) {
        return checkPermission(
          this.permissions,
          "Admin:CampaignManage",
          "EditAdvancedCampaigns",
          true
        );
      }
      return false;
    },
  },
  mounted() {
    this.availableHours = generateArrayOfHours();
    this.availableMinutes = generateArrayOfMinutes();
    this.editItem = JSON.parse(JSON.stringify(this.campaign));
    this.lastEditItem = JSON.stringify(this.campaign);
    if (this.editItem.id) {
      this.currentCampaignId = this.editItem.id;
      // this.currentCampaign = Object.assign({}, this.editItem);

      // this.selectedBudgetId = this.editItem.campaignBudgetId;
      if (this.editItem.campaignBudgetId) {
        this.usePointBudget = true;
        this.selectedPeriodId = this.editItem.periodId;
      } else {
        this.usePointBudget = false;
      }
      this.isNew = false;
    } else {
      this.isNew = true;
      this.usePointBudget = true;
      this.editItem.visibility = "public"; // default
    }

    if (this.editItem.publisherType) {
      // do nothing
    } else if (this.defaultMunicipal.includes(this.editItem.prototype)) {
      this.editItem.publisherType = "municipal";
    } else {
      this.editItem.publisherType = "exclusive";
    }

    this.init();
    // console.log("init wizard:" + JSON.stringify(this.editItem));
  },
  methods: {
    init() {
      this.getCampaignTypes();
      this.getPointSettings();
      this.getOrganizations();
      this.selectedOrganizationId =
        this.editItem.organizationId || this.organizationId;
      this.selectedDepartmentId =
        this.editItem.departmentId || this.departmentId;
      this.getDepartmentList();
    },
    getCampaignType(type) {
      let parsedType = this.availableCampaignTypes.find((t) => t.type === type);
      return parsedType ? parsedType.name : "Unknown";
    },
    getCampaignTypes() {
      this.isLoadingCampaignTypes = true;
      let query = {};
      fetchCampaignTypes(this.merchantId, query)
        .then((res) => {
          console.log("fetchCampaignTypes done", res);
          this.availableCampaignTypes = [...res.data];
        })
        .catch((e) => {
          console.log("fetchMissionTypes failed", e);
        })
        .finally(() => {
          this.isLoadingCampaignTypes = false;
        });
    },
    close() {
      this.$emit("close");
    },
    validate(n) {
      this.steppers[n].valid = false;
      let v = this.$refs.stepForm[n].validate();
      if (v) {
        this.steppers[n].valid = true;
        // continue to next
        if (this.steppers[n].index === 1) {
          this.onUpdateCampaign(n);
        } else {
          this.curr = n + 2;
        }
        // this.curr = n + 2;
      }
    },
    onUpdateCampaign(n) {
      console.log("onUpdateCampaign");
      const start = this.editItem.parsedStartTime;
      const end = this.editItem.parsedEndTime;
      this.editItem.startTime = moment(
        start.date +
          " " +
          start.hour.toString().padStart(2, "0") +
          ":" +
          start.minute.toString().padStart(2, "0") +
          ":" +
          start.seconds.toString().padStart(2, "0")
      ).unix();
      this.editItem.endTime = moment(
        end.date +
          " " +
          end.hour.toString().padStart(2, "0") +
          ":" +
          end.minute.toString().padStart(2, "0") +
          ":" +
          end.seconds.toString().padStart(2, "0")
      ).unix();
      const startAfterEnd = moment(this.editItem.startTime).isAfter(
        moment(this.editItem.endTime)
      );
      if (startAfterEnd) {
        this.$swal({
          text: "開始時間不得小於結束時間",
          type: "error",
          showCancelButton: false,
          confirmButtonText: this.$i18n.t(`common["Confirm"]`),
          customClass: {
            confirmButton: "btn bg-gradient-success",
            cancelButton: "btn bg-gradient-danger",
          },
        });
        return;
      }
      this.editItem.departmentId = this.selectedDepartmentId;
      console.log(
        this.lastEditItem,
        JSON.stringify(this.editItem),
        this.lastEditItem === JSON.stringify(this.editItem)
      );
      if (this.lastEditItem === JSON.stringify(this.editItem)) {
        this.curr = n + 2;
        console.log("no difference");
        return;
      }
      if (this.uploadImg) {
        // Need to replace upload image, add get new file name for save db
        let data = new FormData();
        data.append("file", this.uploadImg);

        uploadImage(this.merchantId, data)
          .then((result) => {
            console.log("upload image done:" + JSON.stringify(result));
            this.editItem.imageUrl = result.data;
            setTimeout(this.updateCampaign(n));
          })
          .catch((e) => {
            console.log("uploadImage failed", e);
            let errmsg = "上傳檔案失敗";
            if (e.response && e.response.data && e.response.data.message) {
              errmsg += "<br/>" + e.response.data.message;
            }
            this.showErrorAlert(errmsg);
          });
      } else {
        this.updateCampaign(n);
      }
    },

    done() {
      this.$emit("close");
    },
    updateCampaign(n) {
      let campaign = {
        id: this.editItem.id,
        name: this.editItem.campaignName,
        desc: this.editItem.description,
        startTime: this.editItem.startTime,
        endTime: this.editItem.endTime,
        visibility: this.editItem.visibility,
        prototype: this.editItem.prototype,
        departmentId: this.editItem.departmentId,
        publisherType: this.editItem.publisherType,
        // imageUrl: this.uploadImgUrl
        //   ? this.uploadImgUrl
        //   : this.editItem.imageUrl,
        // context: this.context,
        campaignBudgetId: this.editItem.campaignBudgetId,
      };

      console.log("updateCampaign:" + JSON.stringify(campaign));
      updateCampaign(this.merchantId, campaign)
        .then((res) => {
          console.log("create/update campaign done", res);
          if (res.data) {
            if (res.data.id && this.isNew) {
              this.currentCampaignId = res.data.id;
              this.editItem.id = res.data.id;
              // this.currentCampaign = Object.assign({
              //   id: this.editItem.id,
              //   name: this.editItem.campaignName,
              //   desc: this.editItem.description,
              //   startTime: this.editItem.startTime,
              //   endTime: this.editItem.endTime,
              //   campaignBudgetId: this.editItem.campaignBudgetId,
              //   pointId: this.selectedPointId,
              // });
            }

            this.lastEditItem = JSON.stringify(this.editItem);

            // setTimeout(function () {
            //   this.curr = n ? n + 2 : 2;
            // }, 10);
            this.curr = n ? n + 2 : 2;
            this.$emit("updated");
          }
        })
        .catch((e) => {
          console.log("create/update campaign failed:", e);
          let errmsg = this.isNew ? "新增任務失敗" : "更新任務失敗";
          if (e.response && e.response.data && e.response.data.message) {
            errmsg += "<br/>" + e.response.data.message;
          }
          this.showErrorAlert(errmsg);
        });
      // this.curr = n ? n + 2 : 2;
    },
    getOrganizations() {
      fetchOrganizations(this.merchantId)
        .then((res) => {
          console.log("fetchOrganizations done", res);
          this.availableOrganizations = [...res.data];
          console.log(
            "this.availableOrganizations:" +
              JSON.stringify(this.availableOrganizations)
          );
        })
        .catch((e) => {
          console.log("fetchOrganizations failed", e);
        });
    },
    getPointSettings() {
      this.isPointSettingsLoaded = false;
      fetchPointSettings(this.merchantId)
        .then((res) => {
          console.log("fetchPointSettings done", res);
          this.availablePoints = [...res.data];
          this.selectedPointId = this.editItem.pointId
            ? this.editItem.pointId
            : this.availablePoints.length
            ? this.availablePoints[0].id
            : null;

          // this.selectedPointId = this.availablePoints.length
          //   ? this.availablePoints[0].id
          //   : null;
          this.$nextTick(this.getPointPeriods());
          // this.$nextTick(this.getCampaignBudgets(this.selectedPointId));
        })
        .catch((e) => {
          console.log("fetchPointSettings failed", e);
        })
        .finally(() => {
          this.isPointSettingsLoaded = true;
        });
    },
    getPointPeriods(pid) {
      // const merchantId = 1;
      this.isPeriodsLoaded = false;
      const pointId = pid ? pid : this.selectedPointId;
      console.log("getPointPeriods:" + pointId);

      fetchPointPeriods(this.merchantId, pointId)
        .then((res) => {
          console.log("fetchPointPeriods done", res);
          // 只能用還未結算的
          this.availablePeriods = res.data.filter((p) => p.closeStatus === 0);
          this.selectedPeriodId = this.selectedPeriodId
            ? this.selectedPeriodId
            : this.availablePeriods.length
            ? this.availablePeriods[0].id
            : null;
          if (this.selectedDepartmentId) {
            this.getCampaignBudgets();
          }
        })
        .catch((e) => {
          console.log("fetchPointPeriods failed", e);
          this.$swal({
            text: "無法取得點數期別資料",
            type: "error",
            showCancelButton: false,
            confirmButtonText: this.$i18n.t(`common["Confirm"]`),
            customClass: {
              confirmButton: "btn bg-gradient-success",
              cancelButton: "btn bg-gradient-danger",
            },
          });
        })
        .finally(() => {
          this.isPeriodsLoaded = true;
        });
    },
    getDepartmentList() {
      this.isDepartmentLoaded = false;
      console.log("getDepartmentList");
      let query = {
        organizationId: this.selectedOrganizationId || this.organizationId,
        departmentId: this.selectedDepartmentId,
      };
      fetchDepartments(this.merchantId, query)
        .then((res) => {
          console.log("fetchDepartments done", res);
          this.availableDepartments = [...res.data];
          // this.selectedDepartmentId = this.availableDepartments[0].id;
          // this.$nextTick(this.getCampaignBudgets());
        })
        .catch((e) => {
          console.log("fetchDepartments failed", e);
          this.$swal({
            text: "無法取得單位資料",
            type: "error",
            showCancelButton: false,
            confirmButtonText: this.$i18n.t(`common["Confirm"]`),
            customClass: {
              confirmButton: "btn bg-gradient-success",
              cancelButton: "btn bg-gradient-danger",
            },
          });
        })
        .finally(() => {
          this.isDepartmentLoaded = true;
        });
    },
    onPointSettingChange() {
      this.getPointPeriods();
    },
    getCampaignBudgets() {
      // const merchantId = 1;
      this.isBudgetsLoaded = false;
      const pointId = this.selectedPointId;
      const periodId = this.selectedPeriodId;
      const departmentId = this.selectedDepartmentId;
      let query = {
        periodId,
        organizationId: this.selectedOrganizationId,
        departmentId,
      };
      console.log("getCampaignBudgets:" + pointId, JSON.stringify(query));

      fetchCampaignBudgets(this.merchantId, pointId, query)
        .then((res) => {
          console.log("fetchCampaignBudgets done", res);
          this.availableBudgets = [...res.data];
        })
        .catch((e) => {
          console.log("fetchCampaignBudgets failed", e);
          this.$swal({
            text: "無法取得活動預算資料",
            type: "error",
            showCancelButton: false,
            confirmButtonText: this.$i18n.t(`common["Confirm"]`),
            customClass: {
              confirmButton: "btn bg-gradient-success",
              cancelButton: "btn bg-gradient-danger",
            },
          });
        })
        .finally(() => {
          this.isBudgetsLoaded = true;
          this.currentPeriodId = periodId;
        });
    },
    onInputChange(data) {
      this.content = data;
    },
    onImageFileChange() {
      if (this.uploadImg) {
        this.uploadImgUrl = URL.createObjectURL(this.uploadImg);
      } else {
        this.uploadImgUrl = null;
      }
    },
    showErrorAlert(message) {
      let title = this.$i18n.t(`errors["Update"]`);
      if (this.isNew) {
        title = this.$i18n.t(`errors["Create"]`);
      }
      this.$swal({
        title: title,
        html: message,
        type: "error",
        showCancelButton: false,
        confirmButtonText: this.$i18n.t(`common["Confirm"]`),
        customClass: {
          confirmButton: "btn bg-gradient-success",
          cancelButton: "btn bg-gradient-danger",
        },
      });
    },
    onToggleUseBudget() {
      if (this.usePointBudget) {
        const temp = JSON.parse(this.lastEditItem);
        this.editItem.campaignBudgetId = temp.campaignBudgetId;
      } else {
        this.editItem.campaignBudgetId = null;
      }
    },
  },
};
</script>

<style scoped>
::v-deep .v-stepper__step__step {
  width: 40px;
  height: 40px;
}
.large-checkbox::v-deep i {
  font-size: 24px;
}
.large-checkbox::v-deep label {
  font-size: 16px;
  padding-left: 3px;
}
.large-checkbox::v-deep .v-radio {
  padding: 0px;
}
</style>
