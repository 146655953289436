<template>
  <v-card>
    <v-card-title>
      <span class="text-h5"> 設定任務關卡 － </span>
      <span class="text-h5">
        {{ missionName }}
      </span>
    </v-card-title>
    <v-card-text>
      <v-container v-if="editItem">
        <v-form ref="editForm" lazy-validation>
          <v-row class="mx-auto">
            <v-col cols="12">
              <v-text-field
                v-model="editItem.missionName"
                label="關卡名稱"
                :rules="rules.required"
                class="font-size-input-lg input-style"
                :disabled="isActionDisabled"
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-textarea
                v-model="editItem.missionDesc"
                outlined
                label="關卡描述"
                rows="3"
                class="font-size-input-lg input-style"
              ></v-textarea>
              <!-- <v-text-field
                v-model="editItem.missionDesc"
                label="關卡描述"
                :rules="rules.required"
                :disabled="isActionDisabled"
              ></v-text-field> -->
            </v-col>
            <v-col cols="12">
              <v-select
                v-model="editItem.data.rewardId"
                label="關卡獎勵"
                :items="availableRewards"
                item-text="rewardName"
                item-value="rewardId"
                class="font-size-input-lg input-style"
                :rules="rules.required"
                :disabled="isActionDisabled"
              >
              </v-select>
            </v-col>
            <v-col cols="12" sm="6" v-show="showRepetitionMode">
              <v-select
                v-model="editItem.data.repetitionMode"
                label="重複參加間隔"
                :items="repetitionModes"
                item-text="name"
                item-value="value"
                class="font-size-input-lg input-style"
                clearable
                :disabled="isActionDisabled"
              >
              </v-select>
            </v-col>
            <v-col cols="12" sm="6" v-show="showMultiCollectible">
              <v-checkbox
                v-model="editItem.data.multiCollectible"
                color="#141727"
                class="ma-0 large-checkbox pb-2"
                :class="$vuetify.breakpoint.xs ? '' : 'pt-6'"
                hide-details
                label="可重複領獎"
                :disabled="isActionDisabled"
              >
              </v-checkbox>
            </v-col>
            <v-col cols="12" v-if="showRedirectUrl">
              <v-text-field
                v-model="editItem.entryUrl"
                label="活動串接網址"
                class="font-size-input-lg input-style"
                :rules="rules.isUrl"
                :disabled="isActionDisabled"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-form>
      </v-container>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
        color="blue darken-1"
        text
        :disabled="isActionDisabled"
        @click="closeEditDialog"
      >
        {{ $t(`common['Close']`) }}
      </v-btn>
      <v-btn
        color="blue darken-1"
        text
        :disabled="isActionDisabled"
        :loading="isProcessing"
        @click="onSaveEditItem"
      >
        {{ $t(`common['Save']`) }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import moment from "moment";
import { mapState } from "vuex";

import { fetchCampaignRewards, updateMission } from "src/apis/campaignCore";
import { isURL } from "src/util/utils";
import variables from "src/util/variables";

export default {
  name: "BasicMissionForm",
  props: {
    inputItem: {
      type: Object,
      default: function () {
        return {};
      },
      required: true,
    },
    missionName: {
      type: String,
      default: "",
      required: true,
    },
    campaignId: {
      type: String,
      default: null,
      required: true,
    },
  },
  components: {},
  data() {
    return {
      moment: moment,
      editItem: null,
      isProcessing: false,
      isLoadingRewards: false,
      availableRewards: [],
      //availableVisibilityOptions: [],
      rules: {
        required: [(v) => !!v || this.$t(`common["Required"]`)],
        isUrl: [
          (value) => isURL(value) || this.$t(`common["URL is not valid"]`),
        ],
      },
    };
  },
  computed: {
    ...mapState({
      isLoggedIn: (state) => state.isLoggedIn,
      ssoUser: (state) => state.ssoUser,
    }),
    isActionDisabled() {
      return this.isProcessing || this.isLoadingRewards;
    },
    merchantId() {
      return this.isLoggedIn ? this.ssoUser.user._json.groups.merchant : null;
    },
    organizationId() {
      return this.isLoggedIn
        ? this.ssoUser.user._json.groups.organization
        : null;
    },
    isNew() {
      return this.editItem.missionId ? false : true;
    },
    showMultiCollectible() {
      let show = false;
      switch (this.editItem.missionType) {
        case "event-driven":
        case "donate-invoice":
          show = true;
          break;
        case "question":
        case "registration":
        case "referral-recipient":
        default:
          show = false;
          break;
      }
      return show;
    },
    showRepetitionMode() {
      let show = false;
      switch (this.editItem.missionType) {
        case "event-driven":
        case "donate-invoice":
          show = true;
          break;
        default:
          show = false;
          break;
      }
      return show;
    },
    showRedirectUrl() {
      let show = false;
      switch (this.editItem.missionType) {
        case "event-driven":
          show = true;
          break;
        default:
          show = false;
          break;
      }
      return show;
    },
    repetitionModes() {
      return variables.videoRepetitionModes;
    },
    // availableVisibilityOptions() {
    //   return variables.missionVisibiltyOptions;
    // },
  },
  watch: {},
  created() {},
  mounted() {
    this.editItem = Object.assign({}, this.inputItem);
    if (this.editItem.missionId) {
      this.editItem.data = Object.assign({}, this.editItem.settings);
    }
    this.getRewards();
  },
  methods: {
    getRewards() {
      this.isLoadingRewards = true;
      let query = {};
      console.log("getRewards campaign:" + this.campaignId);
      fetchCampaignRewards(this.merchantId, this.campaignId, query)
        .then((res) => {
          console.log("fetchCampaignRewards done", res);
          this.availableRewards = [...res.data];
        })
        .catch((e) => {
          console.log("fetchCampaignRewards failed", e);
        })
        .finally(() => {
          this.isLoadingRewards = false;
        });
    },
    closeEditDialog() {
      this.$refs.editForm.resetValidation();
      this.$refs.editForm.reset();
      this.$emit("close");
    },
    onSaveEditItem() {
      const valid = this.$refs.editForm.validate();
      this.editItem.name = this.editItem.missionName;
      this.editItem.desc = this.editItem.missionDesc;
      this.isProcessing = true;
      if (valid) {
        // this.$emit("save");
        const saveItem = Object.assign({}, this.editItem);
        if (!saveItem.data.repetitionMode) {
          saveItem.data.repetitionMode = undefined;
        }
        if (!saveItem.data.multiCollectible) {
          saveItem.data.multiCollectible = undefined;
        }
        updateMission(this.merchantId, this.campaignId, saveItem)
          .then((res) => {
            console.log("updateMission done", res);
            if (res && res.data) {
              this.editItem.missionId = res.data.missionId;
            }
            // this.closeEditDialog();
            this.$refs.editForm.resetValidation();
            this.$refs.editForm.reset();
            this.$emit("save");
          })
          .catch((e) => {
            console.log("updateMission failed", e);
            let errmsg = this.isNew ? "新增任務關卡失敗" : "更新任務關卡失敗";
            if (e.response && e.response.data && e.response.data.message) {
              errmsg += "<br/>" + e.response.data.message;
            }
            this.showErrorAlert(errmsg);
          })
          .finally(() => {
            this.isProcessing = false;
          });
      } else {
        this.showErrorAlert(this.$i18n.t(`errors["Incorrect Fields"]`));
        this.isProcessing = false;
      }
    },
    showErrorAlert(message) {
      let title = this.$i18n.t(`errors["Update"]`);

      if (this.isNew) {
        title = this.$i18n.t(`errors["Create"]`);
      }
      this.$swal({
        title: title,
        html: message,
        type: "error",
        showCancelButton: false,
        confirmButtonText: this.$i18n.t(`common["Confirm"]`),
        customClass: {
          confirmButton: "btn bg-gradient-success",
          cancelButton: "btn bg-gradient-danger",
        },
      });
    },
  },
};
</script>
<style scoped>
.large-checkbox::v-deep i {
  font-size: 24px;
}
.large-checkbox::v-deep label {
  font-size: 16px;
  padding-left: 3px;
}
.large-checkbox::v-deep .v-radio {
  padding: 0px;
}
</style>
