<template>
  <v-card>
    <v-card-title>
      <span class="text-h5"> 設定任務關卡 － </span>
      <span class="text-h5">
        {{ missionName }}
      </span>
    </v-card-title>
    <v-card-text>
      <p class="text-sm text-capitalize text-body font-weight-light">
        於特定店家消費，若達到消費門檻，即可獲得印花；依據領獎規則設定，集滿不同數量的印花，可獲得對應獎勵。
      </p>
      <v-container v-if="editItem">
        <v-form ref="editForm" lazy-validation>
          <v-row class="mx-auto">
            <v-col cols="12">
              <v-text-field
                v-model="editItem.missionName"
                label="關卡名稱"
                :rules="rules.required"
                class="font-size-input-lg input-style"
                :disabled="isActionDisabled"
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-textarea
                v-model="editItem.missionDesc"
                outlined
                label="關卡描述"
                rows="3"
                class="font-size-input-lg input-style"
              ></v-textarea>
            </v-col>
            <!-- <v-col cols="12">
              <v-text-field
                v-model="editItem.redirectUrl"
                label="活動串接網址"
                class="font-size-input-lg input-style"
                :rules="rules.isUrl"
                :disabled="isActionDisabled"
              ></v-text-field>
            </v-col> -->
            <v-col cols="12" sm="6" v-if="false">
              <v-checkbox
                v-model="editItem.data.multiCollectible"
                color="#141727"
                class="ma-0 large-checkbox"
                hide-details
                label="可重複領獎"
                :disabled="isActionDisabled"
              >
              </v-checkbox>
            </v-col>
            <v-col cols="12" sm="6" v-if="false">
              <v-checkbox
                v-model="editItem.data.cumulative"
                color="#141727"
                class="ma-0 large-checkbox"
                hide-details
                label="可累贈"
                :disabled="isActionDisabled"
              >
              </v-checkbox>
            </v-col>
            <v-col cols="12">
              <v-text-field
                v-model="editItem.data.pointsThreshold"
                label="消費門檻（消費滿N元，才符合資格）"
                type="number"
                :rules="rules.integer"
                class="font-size-input-lg input-style"
                clearable
                @input="
                  $event === ''
                    ? (editItem.data.pointsThreshold = 0)
                    : (editItem.data.pointsThreshold = $event)
                "
                :disabled="isActionDisabled"
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-row>
                <v-col cols="4" sm="6">
                  <label class="text-sm text-body">領獎規則</label>
                  <v-text-field
                    v-model="inputRule.completionCount"
                    label="印花數量"
                    type="number"
                    :rules="rules.positiveInteger"
                    class="font-size-input-lg input-style"
                    clearable
                    :disabled="isActionDisabled"
                  ></v-text-field>
                </v-col>
                <v-col cols="8" sm="6">
                  <v-select
                    v-model="inputRule.rewardId"
                    label="獎項"
                    :items="availableRewards"
                    item-text="rewardName"
                    item-value="rewardId"
                    :disabled="isActionDisabled"
                    class="font-size-input-lg input-style mt-6"
                  >
                    <!-- <template slot="selection" slot-scope="data">
                      {{ data.item.rewardName }}
                    </template>
                    <template slot="item" slot-scope="data">
                      {{ data.item.rewardName }}
                    </template> -->
                    <template v-slot:append-outer>
                      <v-btn @click="addRewardRule" small dark class="mx-2">
                        <v-icon> fa-lg fa-plus </v-icon>
                      </v-btn>
                    </template>
                  </v-select>
                </v-col>

                <!-- <small> *獎項數量需為2、4或8 </small> -->
              </v-row>
              <v-row>
                <v-col cols="12">
                  <v-list-item v-for="(r, index) in milestones" :key="index">
                    <v-list-item-title>
                      規則 {{ index + 1 }}： 集滿
                      <span class="text-weight-bold" style="color: red">
                        {{ r.completionCount }}
                      </span>
                      章， 可獲得獎勵「 {{ getRewardType(r.rewardId) }}」
                    </v-list-item-title>

                    <v-list-item-action>
                      <v-btn
                        class="mx-2"
                        dark
                        fab
                        x-small
                        color="pink darken-2"
                        @click="removeRewardRule(index)"
                      >
                        <v-icon>fas fa-trash</v-icon>
                      </v-btn>
                    </v-list-item-action>
                  </v-list-item>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-form>
        <br />
      </v-container>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
        color="blue darken-1"
        text
        :disabled="isActionDisabled"
        @click="closeEditDialog"
      >
        {{ $t(`common['Close']`) }}
      </v-btn>
      <v-btn
        color="blue darken-1"
        text
        :disabled="isActionDisabled"
        :loading="isProcessing"
        @click="onSaveEditItem"
      >
        {{ $t(`common['Save']`) }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import moment from "moment";
import { mapState } from "vuex";

import { fetchCampaignRewards, updateMission } from "src/apis/campaignCore";
import { isURL, isInteger, isPositiveInteger } from "src/util/utils";
import variables from "src/util/variables";

export default {
  name: "StampCollectionForm",
  props: {
    inputItem: {
      type: Object,
      default: function () {
        return {};
      },
      required: true,
    },
    missionName: {
      type: String,
      default: "",
      required: true,
    },
    campaignId: {
      type: String,
      default: null,
      required: true,
    },
  },
  components: {},
  data() {
    return {
      rewards: [],
      moment: moment,
      editItem: null,
      isProcessing: false,
      isLoadingRewards: false,
      availableRewards: [],
      rules: {
        required: [(v) => !!v || this.$t(`common["Required"]`)],
        isUrl: [
          (value) => isURL(value) || this.$t(`common["URL is not valid"]`),
        ],
        positiveInteger: [
          (v) =>
            isPositiveInteger(v) ||
            this.$t(`validation["Must be positive integer"]`),
        ],
        integer: [
          (v) => isInteger(v) || this.$t(`validation["Must be integer"]`),
        ],
      },
      inputRule: {
        completionCount: null,
        rewardId: null,
      },
      milestones: [],
    };
  },
  computed: {
    ...mapState({
      isLoggedIn: (state) => state.isLoggedIn,
      ssoUser: (state) => state.ssoUser,
    }),
    isActionDisabled() {
      return this.isProcessing || this.isLoadingRewards;
    },
    merchantId() {
      return this.isLoggedIn ? this.ssoUser.user._json.groups.merchant : null;
    },
    organizationId() {
      return this.isLoggedIn
        ? this.ssoUser.user._json.groups.organization
        : null;
    },
    isNew() {
      return this.editItem.missionId ? false : true;
    },
  },
  watch: {},
  created() {},
  mounted() {
    this.editItem = Object.assign({}, this.inputItem);
    if (this.editItem.missionId) {
      this.editItem.data = Object.assign({}, this.editItem.settings);
    }
    if (this.editItem.settings && this.editItem.settings.milestones) {
      this.milestones = [...this.editItem.settings.milestones];
    }
    // Deprecated
    // if (this.editItem.context) {
    //   const context = JSON.parse(this.editItem.context);

    //   if (context && context.milestones) {
    //     this.milestones = [...context.milestones];
    //   }
    //   console.log("--milestones---", JSON.stringify(this.milestones));
    // }
    this.getRewards();
  },
  methods: {
    getRewardType(id) {
      const r = this.availableRewards.filter((e) => e.rewardId === id);
      return r.length ? r[0].rewardName : "";
    },
    addRewardRule() {
      if (!this.inputRule.completionCount && !this.inputRule.rewardId) {
        return;
      }
      if (!this.inputRule.completionCount || !this.inputRule.rewardId) {
        this.$swal({
          text: "請選擇獎項並填寫領奬規則",
          type: "error",
          showCancelButton: false,
          buttonStyle: false,
          confirmButtonText: this.$i18n.t(`common["Confirm"]`),
          customClass: {
            confirmButton: "btn bg-gradient-success",
            cancelButton: "btn bg-gradient-danger",
          },
        });
        return;
      }
      let duplicated = [];

      if (this.milestones.length) {
        duplicated = this.milestones.filter(
          (e) =>
            e.rewardId === this.inputRule.rewardId ||
            e.completionCount === this.inputRule.completionCount
        );
      }

      if (duplicated.length > 0) {
        this.$swal({
          html: "重複的領獎規則<br/>請確認集章數量與獎項是否重複設定",
          type: "error",
          showCancelButton: false,
          buttonStyle: false,
          confirmButtonText: this.$i18n.t(`common["Confirm"]`),
          customClass: {
            confirmButton: "btn bg-gradient-success",
            cancelButton: "btn bg-gradient-danger",
          },
        });
        return;
      }

      this.milestones.push({
        completionCount: this.inputRule.completionCount,
        rewardId: this.inputRule.rewardId,
      });
      // this.rewards.push(this.inputReward.rewardId);
      this.inputRule.completionCount = null;
      this.inputRule.rewardId = null;
    },
    removeRewardRule(index) {
      this.milestones.splice(index, 1);
    },
    getRewards() {
      this.isLoadingRewards = true;
      let query = {};
      console.log("getRewards campaign:" + this.campaignId);
      fetchCampaignRewards(this.merchantId, this.campaignId, query)
        .then((res) => {
          console.log("fetchCampaignRewards done", res);
          this.availableRewards = [...res.data];
        })
        .catch((e) => {
          console.log("fetchCampaignRewards failed", e);
        })
        .finally(() => {
          this.isLoadingRewards = false;
        });
    },
    closeEditDialog() {
      this.$refs.editForm.resetValidation();
      this.$refs.editForm.reset();
      this.$emit("close");
    },
    onSaveEditItem() {
      const valid = this.$refs.editForm.validate();
      this.editItem.name = this.editItem.missionName;
      this.editItem.desc = this.editItem.missionDesc;
      this.editItem.entryUrl = variables.entryUrls["stamp-collection"];
      this.isProcessing = true;
      // console.log(valid, this.editItem);
      if (valid) {
        // this.$emit("save");
        if (this.milestones.length === 0) {
          this.showErrorAlert("請設定領獎規則");
          this.isProcessing = false;
          return;
        }
        this.editItem.data.milestones = [...this.milestones];
        this.editItem.data.pointsThreshold = this.editItem.data.pointsThreshold
          ? parseInt(this.editItem.data.pointsThreshold)
          : 0;
        updateMission(this.merchantId, this.campaignId, this.editItem)
          .then((res) => {
            console.log("updateMission done", res);
            if (res && res.data) {
              this.editItem.missionId = res.data.missionId;
            }
            // this.closeEditDialog();
            this.$refs.editForm.resetValidation();
            this.$refs.editForm.reset();
            this.$emit("save");
          })
          .catch((e) => {
            console.log("updateMission failed", e);
            let errmsg = this.isNew ? "新增任務關卡失敗" : "更新任務關卡失敗";
            if (e.response && e.response.data && e.response.data.message) {
              errmsg += "<br/>" + e.response.data.message;
            }
            this.showErrorAlert(errmsg);
          })
          .finally(() => {
            this.isProcessing = false;
          });
      } else {
        this.showErrorAlert(this.$i18n.t(`errors["Incorrect Fields"]`));
        this.isProcessing = false;
      }
    },
    showErrorAlert(message) {
      let title = this.$i18n.t(`errors["Update"]`);
      if (this.isNew) {
        title = this.$i18n.t(`errors["Create"]`);
      }
      this.$swal({
        title: title,
        html: message,
        type: "error",
        showCancelButton: false,
        confirmButtonText: this.$i18n.t(`common["Confirm"]`),
        customClass: {
          confirmButton: "btn bg-gradient-success",
          cancelButton: "btn bg-gradient-danger",
        },
      });
    },
  },
};
</script>
<style scoped>
.large-checkbox::v-deep i {
  font-size: 24px;
}
.large-checkbox::v-deep label {
  font-size: 16px;
  padding-left: 3px;
}
.large-checkbox::v-deep .v-radio {
  padding: 0px;
}
</style>
