<template>
  <v-card>
    <v-card-title>
      <span class="text-h5"> 設定任務關卡 － </span>
      <span class="text-h5">
        {{ missionName }}
      </span>
    </v-card-title>
    <v-card-text>
      <p class="text-sm text-capitalize text-body font-weight-light">
        最多可設定8種不同獎項，獎項數量須為2、4或8，設定之各獎項出現的機率為（獎項數/8）
      </p>
      <v-container v-if="editItem">
        <v-form ref="editForm" lazy-validation>
          <v-row class="mx-auto">
            <v-col cols="12">
              <v-text-field
                v-model="editItem.missionName"
                label="關卡名稱"
                :rules="rules.required"
                class="font-size-input-lg input-style"
                :disabled="isActionDisabled"
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-textarea
                v-model="editItem.missionDesc"
                outlined
                label="關卡描述"
                rows="3"
                class="font-size-input-lg input-style"
                :rules="rules.required"
              ></v-textarea>
              <!-- <v-text-field
                v-model="editItem.missionDesc"
                label="關卡描述"
                :rules="rules.required"
                :disabled="isActionDisabled"
              ></v-text-field> -->
            </v-col>
            <v-col cols="12" v-if="false">
              <v-text-field
                v-model="editItem.redirectUrl"
                label="活動串接網址"
                class="font-size-input-lg input-style"
                :rules="rules.isUrl"
                :disabled="isActionDisabled"
              ></v-text-field>
            </v-col>
            <v-col cols="12" v-if="false">
              <v-checkbox
                v-model="editItem.data.multiCollectible"
                color="#141727"
                class="ma-0 large-checkbox"
                hide-details
                label="可重複領獎"
                :disabled="isActionDisabled"
              >
              </v-checkbox>
            </v-col>

            <v-col cols="12">
              <v-row>
                <v-col cols="12">
                  <v-select
                    v-model="inputReward"
                    label="獎項"
                    :items="availableRewards"
                    :disabled="isActionDisabled"
                    class="font-size-input-lg input-style"
                  >
                    <template slot="selection" slot-scope="data">
                      <!-- HTML that describe how select should render selected items -->
                      {{ data.item.rewardName }}
                    </template>
                    <template slot="item" slot-scope="data">
                      <!-- HTML that describe how select should render items when the select is open -->
                      {{ data.item.rewardName }}
                    </template>
                    <template v-slot:append-outer>
                      <v-btn @click="addReward" small dark class="mx-2">
                        <v-icon> fa-lg fa-plus </v-icon>
                      </v-btn>
                    </template>
                  </v-select>
                </v-col>
                <!-- <v-col cols="6">
                  <v-text-field
                    v-model="inputWeight"
                    label="權重"
                    class="font-size-input-lg input-style"
                    :disabled="isActionDisabled"
                  >
                    <template v-slot:append-outer>
                      <v-btn @click="addReward" small dark class="mx-2">
                        <v-icon> fa-lg fa-plus </v-icon>
                      </v-btn>
                    </template>
                  </v-text-field>
                </v-col> -->
                <!-- <small> *獎項數量需為2、4或8 </small> -->
              </v-row>
              <v-row>
                <v-list-item v-for="(r, index) in rewards" :key="index">
                  <v-list-item-title>
                    獎項 {{ index + 1 }}：{{ r.rewardName }}
                  </v-list-item-title>

                  <v-list-item-action>
                    <v-btn
                      class="mx-2"
                      dark
                      fab
                      x-small
                      color="pink darken-2"
                      @click="removeReward(index)"
                    >
                      <v-icon>fas fa-trash</v-icon>
                    </v-btn>
                  </v-list-item-action>
                </v-list-item>
              </v-row>
            </v-col>
          </v-row>
        </v-form>
        <br />
        <!-- <v-simple-table>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">Name</th>
                <th class="text-left">Calories</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in rewards" :key="item.rewardId">
                <td>{{ item.rewardName }}</td>
                <td>
                  <v-btn
                    class="mx-2"
                    dark
                    fab
                    x-small
                    color="pink darken-2"
                    @click="rewards.splice(index, 1)"
                  >
                    <v-icon>fas fa-trash</v-icon>
                  </v-btn>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table> -->
      </v-container>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
        color="blue darken-1"
        text
        :disabled="isActionDisabled"
        @click="closeEditDialog"
      >
        {{ $t(`common['Close']`) }}
      </v-btn>
      <v-btn
        color="blue darken-1"
        text
        :disabled="isActionDisabled"
        :loading="isProcessing"
        @click="onSaveEditItem"
      >
        {{ $t(`common['Save']`) }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import moment from "moment";
import { mapState } from "vuex";

import { fetchCampaignRewards, updateMission } from "src/apis/campaignCore";
import { isURL } from "src/util/utils";
import variables from "src/util/variables";

export default {
  name: "LuckyDrawForm",
  props: {
    inputItem: {
      type: Object,
      default: function () {
        return {};
      },
      required: true,
    },
    missionName: {
      type: String,
      default: "",
      required: true,
    },
    campaignId: {
      type: String,
      default: null,
      required: true,
    },
  },
  components: {},
  data() {
    return {
      rewards: [],
      moment: moment,
      editItem: null,
      isProcessing: false,
      isLoadingRewards: false,
      availableRewards: [],
      rules: {
        required: [(v) => !!v || this.$t(`common["Required"]`)],
        isUrl: [
          (value) => isURL(value) || this.$t(`common["URL is not valid"]`),
        ],
      },
      inputReward: null,
      inputWeight: null,
      maxRewardLimit: 8,
    };
  },
  computed: {
    ...mapState({
      isLoggedIn: (state) => state.isLoggedIn,
      ssoUser: (state) => state.ssoUser,
    }),
    isActionDisabled() {
      return this.isProcessing || this.isLoadingRewards;
    },
    merchantId() {
      return this.isLoggedIn ? this.ssoUser.user._json.groups.merchant : null;
    },
    organizationId() {
      return this.isLoggedIn
        ? this.ssoUser.user._json.groups.organization
        : null;
    },
    isNew() {
      return this.editItem.missionId ? false : true;
    },
  },
  watch: {},
  created() {},
  mounted() {
    this.editItem = Object.assign({}, this.inputItem);
    console.log("LuckyDrawForm:" + JSON.stringify(this.editItem));
    if (this.editItem.missionId) {
      this.editItem.data = Object.assign({}, this.editItem.settings);
    }
    if (this.editItem.missionId && this.editItem.data.prizes) {
      this.rewards = [...this.editItem.data.prizes];
    } else {
      this.rewards = [];
    }

    this.getRewards();
  },
  methods: {
    // getRewardType(id) {
    //   const r = this.availableRewards.filter((e) => e.rewardId === id);
    //   return r.length ? r[0].rewardName : "";
    // },
    addReward() {
      if (!this.inputReward) return;
      if (this.rewards.length === this.maxRewardLimit) {
        this.$swal({
          text: "最多只可設定8種獎項",
          type: "error",
          showCancelButton: false,
          buttonStyle: false,
          confirmButtonText: this.$i18n.t(`common["Confirm"]`),
          customClass: {
            confirmButton: "btn bg-gradient-success",
            cancelButton: "btn bg-gradient-danger",
          },
        });
        return;
      }
      // if (!this.inputReward || !this.inputWeight) {
      //   this.$swal({
      //     text: "請選擇獎項並填寫該獎項的機率比重",
      //     type: "error",
      //     showCancelButton: false,
      //     buttonStyle: false,
      //     confirmButtonText: this.$i18n.t(`common["Confirm"]`),
      //     customClass: {
      //       confirmButton: "btn bg-gradient-success",
      //       cancelButton: "btn bg-gradient-danger",
      //     },
      //   });
      //   return;
      // }
      const duplicated = this.rewards.filter(
        (e) => e.rewardId === this.inputReward.rewardId
      );
      // const duplicated = this.rewards.filter(
      //   (e) => e === this.inputReward.rewardId
      // );
      if (duplicated.length > 0) {
        this.$swal({
          text: "獎勵內容重複",
          type: "error",
          showCancelButton: false,
          buttonStyle: false,
          confirmButtonText: this.$i18n.t(`common["Confirm"]`),
          customClass: {
            confirmButton: "btn bg-gradient-success",
            cancelButton: "btn bg-gradient-danger",
          },
        });
        return;
      }

      this.rewards.push({
        rewardId: this.inputReward.rewardId,
        rewardName: this.inputReward.rewardName,
        // weight: this.inputWeight,
      });
      // this.rewards.push(this.inputReward.rewardId);
      this.inputReward = null;
      this.inputWeight = null;
    },
    removeReward(index) {
      // let rewards = this.editItem.rewards;
      // rewards.splice(index, 1);
      // this.editItem.rewards = rewards;

      this.rewards.splice(index, 1);
    },
    getRewards() {
      this.isLoadingRewards = true;
      let query = {};
      console.log("getRewards campaign:" + this.campaignId);
      fetchCampaignRewards(this.merchantId, this.campaignId, query)
        .then((res) => {
          console.log("fetchCampaignRewards done", res);
          this.availableRewards = [...res.data];
        })
        .catch((e) => {
          console.log("fetchCampaignRewards failed", e);
        })
        .finally(() => {
          this.isLoadingRewards = false;
        });
    },
    closeEditDialog() {
      this.rewards = [];
      this.$refs.editForm.resetValidation();
      this.$refs.editForm.reset();
      if (this.editItem.data.prizes && !this.editItem.missionId) {
        this.editItem.data = {};
      }
      this.$emit("close");
    },
    onSaveEditItem() {
      const valid = this.$refs.editForm.validate();
      const prizesCount = this.rewards.length;
      // if (!(prizesCount === 2 || prizesCount === 4 || prizesCount === 8)) {
      //   this.$swal({
      //     text: "獎項數量需為2、4或8",
      //     type: "error",
      //     showCancelButton: false,
      //     confirmButtonText: this.$i18n.t(`common["Confirm"]`),
      //     customClass: {
      //       confirmButton: "btn bg-gradient-success",
      //       cancelButton: "btn bg-gradient-danger",
      //     },
      //   });
      //   return;
      // }
      if (prizesCount < 1) {
        this.$swal({
          text: "請設定獎項",
          type: "error",
          showCancelButton: false,
          confirmButtonText: this.$i18n.t(`common["Confirm"]`),
          customClass: {
            confirmButton: "btn bg-gradient-success",
            cancelButton: "btn bg-gradient-danger",
          },
        });
        return;
      }
      this.editItem.name = this.editItem.missionName;
      this.editItem.desc = this.editItem.missionDesc;
      this.editItem.entryUrl = variables.entryUrls["minigame"];
      this.editItem.data.prizes = [...this.rewards];
      this.isProcessing = true;

      // const weight = 8 / prizesCount;
      const weight = 1;
      this.editItem.data.prizes.forEach((p) => (p.weight = weight));
      // console.log(valid, this.editItem);
      if (valid) {
        // this.$emit("save");
        updateMission(this.merchantId, this.campaignId, this.editItem)
          .then((res) => {
            console.log("updateMission done", res);
            if (res && res.data) {
              this.editItem.missionId = res.data.missionId;
            }
            // this.closeEditDialog();
            this.$refs.editForm.resetValidation();
            this.$refs.editForm.reset();
            this.rewards = [];
            this.$emit("save");
          })
          .catch((e) => {
            console.log("updateMission failed", e);
            let errmsg = this.isNew ? "新增任務關卡失敗" : "更新任務關卡失敗";
            if (e.response && e.response.data && e.response.data.message) {
              errmsg += "<br/>" + e.response.data.message;
            }
            this.showErrorAlert(errmsg);
          })
          .finally(() => {
            this.isProcessing = false;
          });
      } else {
        this.showErrorAlert(this.$i18n.t(`errors["Incorrect Fields"]`));
        this.isProcessing = false;
      }
    },
    showErrorAlert(message) {
      let title = this.$i18n.t(`errors["Update"]`);
      if (this.isNew) {
        title = this.$i18n.t(`errors["Create"]`);
      }
      this.$swal({
        title: title,
        html: message,
        type: "error",
        showCancelButton: false,
        confirmButtonText: this.$i18n.t(`common["Confirm"]`),
        customClass: {
          confirmButton: "btn bg-gradient-success",
          cancelButton: "btn bg-gradient-danger",
        },
      });
    },
  },
};
</script>
<style scoped>
.large-checkbox::v-deep i {
  font-size: 24px;
}
.large-checkbox::v-deep label {
  font-size: 16px;
  padding-left: 3px;
}
.large-checkbox::v-deep .v-radio {
  padding: 0px;
}
</style>
