<template>
  <v-card>
    <v-toolbar dark color="blue darken-2">
      <v-toolbar-title
        >任務參與狀況 - {{ campaign.campaignName }}</v-toolbar-title
      >
      <v-spacer></v-spacer>
      <v-toolbar-items>
        <v-btn fab plain @click="close">
          <v-icon> fas fa-times</v-icon>
        </v-btn>
      </v-toolbar-items>
    </v-toolbar>

    <v-row>
      <v-col cols="12">
        <v-card class="px-1 py-1">
          <v-card-text>
            <v-row>
              <v-col md="2" cols="6">
                <CardStatNumber
                  :number="stats.count.all"
                  title="參與人數"
                ></CardStatNumber>
              </v-col>
              <v-col md="2" cols="6">
                <CardStatNumber
                  :number="stats.count.completed"
                  title="完成人數"
                ></CardStatNumber>
              </v-col>
              <v-col md="2" cols="6">
                <CardStatNumber
                  :number="campaign.usedPoints"
                  title="已發點數"
                ></CardStatNumber>
              </v-col>
              <v-col md="2" cols="6">
                <CardStatNumber
                  :number="campaign.availablePoints"
                  title="剩餘點數"
                ></CardStatNumber>
              </v-col>
            </v-row>
            <v-row>
              <v-col md="4" sm="6" cols="12">
                <DoughnutChart
                  id="doughnut-chart-participants"
                  title="活動人數"
                  :datasets="chartData.participants"
                  :labels="chartLabels.participants"
                  :affiliates="affiliates.participants"
                />
              </v-col>
              <v-col md="4" sm="6" cols="12">
                <DoughnutChart
                  id="doughnut-chart-citizen"
                  title="會員類別"
                  :datasets="chartData.citizenship"
                  :labels="chartLabels.citizenship"
                  :affiliates="affiliates.citizenship"
                />
              </v-col>
              <v-col md="4" sm="6" cols="12">
                <DoughnutChart
                  id="doughnut-chart-gender"
                  title="參與性別"
                  :datasets="chartData.gender"
                  :labels="chartLabels.gender"
                  :affiliates="affiliates.gender"
                />
              </v-col>
            </v-row>
            <!-- <v-row v-else class="align-center">
              <v-col cols="12">
                <div class="align-center text-center">
                  <v-progress-circular
                    :size="50"
                    color="primary"
                    indeterminate
                  >載入中</v-progress-circular>
                </div>
              </v-col>
            </v-row> -->
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-card-title class="justify-center">
      <div>
        <v-row>
          <v-col cols="12" sm="3">
            <v-text-field
              v-model="search"
              append-icon="fa-search"
              label="以電話搜尋"
              single-line
              hide-details
              clearable
              :disabled="isDetailLoading"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="3">
            <v-menu
              v-model="showStartPicker"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="290px"
              name="startDate"
              :disabled="isDetailLoading"
            >
              <template v-slot:activator="{ on }">
                <v-text-field
                  v-model="startDate"
                  label="開始日期"
                  readonly
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="startDate"
                color="blue-grey lighten-1"
                @input="showStartPicker = false"
              ></v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="12" sm="3">
            <v-menu
              v-model="showEndPicker"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="290px"
              name="endDate"
              :disabled="isDetailLoading"
            >
              <template v-slot:activator="{ on }">
                <v-text-field
                  v-model="endDate"
                  label="結束日期"
                  readonly
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="endDate"
                color="blue-grey lighten-1"
                @input="showEndPicker = false"
              ></v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="12" sm="3" :class="$vuetify.breakpoint.xs ? '' : 'pt-4'">
            <v-btn
              color="blue-grey"
              class="
                font-weight-normal
                text-capitalize
                ma-2
                white--text
                py-3
                px-6
                ms-3
              "
              :disabled="isDetailLoading"
              :loading="isDetailLoading"
              @click="
                pagination.page = 1;
                getDataFromApi();
              "
            >
              {{ $t(`common["Search"]`) }}
            </v-btn>
          </v-col>
        </v-row>
      </div>
    </v-card-title>

    <v-card-text>
      <v-data-table
        :headers="tableDetailHeaders"
        :items="details"
        :options.sync="pagination"
        :server-items-length="totalDetails"
        :loading="isDetailLoading"
        :loading-text="$t(`common['Loading... Please wait']`)"
        :no-data-text="$t(`common['No Data']`)"
        :no-results-text="$t(`common['No Data']`)"
        :footer-props="{
          'items-per-page-text': $t(`common['Rows per page']`),
        }"
        :page.sync="pagination.page"
        hide-default-footer
        @page-count="pagination.pageCount = $event"
        :items-per-page="pagination.itemsPerPage"
      >
        <template v-slot:item.gender="{ item }">
          <span v-if="item.gender === 'f'">{{ $t(`common['Female']`) }}</span>
          <span v-else>{{ $t(`common['Male']`) }}</span>
        </template>
        <template v-slot:item.isTyCitizen="{ item }">
          <span v-if="item.isTyCitizen">
            {{ $t(`common['Yes']`) }}
          </span>
          <span v-else class="align-center"> {{ $t(`common['No']`) }} </span>
        </template>
        <template v-slot:item.createdAt="{ item }">
          <span>{{
            item.createdAt
              ? moment.unix(item.createdAt).format("MM/DD/YYYY HH:mm:ss")
              : ""
          }}</span>
        </template>
        <template v-slot:item.completeness="{ item }">
          <!-- <span v-if="(item.completeness = 'completed')"> ✅ </span> -->
          <v-icon
            v-if="item.completeness === 'completed'"
            color="green darken-2"
          >
            fa-solid fa-check
          </v-icon>
        </template>
        <!-- <template v-slot:item.completedAt="props">
          <span>{{ getCompletedTime(props.item.missionLogs) }}</span>
        </template> -->
      </v-data-table>
    </v-card-text>
    <v-card-actions class="card-padding">
      <Pagination
        :pagination="pagination"
        :total="totalDetails"
        :loading="isDetailLoading"
        @update="getDataFromApi"
      ></Pagination>
    </v-card-actions>
  </v-card>
</template>

<script>
import moment from "moment";
import { mapState } from "vuex";
import { formatNumber } from "src/util/utils";

import {
  fetchCampaignParticipants,
  fetchCampaignParticipantStats,
} from "src/apis/campaignCore";
import Pagination from "./Pagination.vue";
import CardStatNumber from "./CardStatNumber.vue";
import DoughnutChart from "./DoughnutChart.vue";

export default {
  name: "ParticipantList",
  components: { Pagination, CardStatNumber, DoughnutChart },
  props: {
    campaign: {
      type: Object,
      default: function () {
        return {};
      },
      required: true,
    },
  },
  data() {
    return {
      moment: moment,
      formatNumber: formatNumber,
      showStartPicker: false,
      showEndPicker: false,
      startDate: null,
      endDate: null,
      search: null,
      isDetailLoading: false,
      isStatsLoaded: false,
      totalDetails: 0,
      pagination: {
        page: 1,
        pageCount: 0,
        itemsPerPage: 10,
      },
      details: [],
      tableDetailHeaders: [
        {
          text: "姓名",
          sortable: false,
          filterable: false,
          value: "name",
        },
        {
          text: "電話",
          sortable: false,
          filterable: false,
          value: "phone",
        },
        {
          text: "電子郵件",
          sortable: false,
          filterable: false,
          value: "email",
        },
        {
          text: "性別",
          sortable: false,
          filterable: false,
          align: "center",
          value: "gender",
        },

        {
          text: "桃園市民",
          sortable: false,
          filterable: false,
          align: "center",
          value: "isTyCitizen",
        },
        {
          text: "參與時間",
          sortable: false,
          filterable: false,
          value: "createdAt",
        },
        {
          text: "完成",
          sortable: false,
          filterable: false,
          align: "center",
          value: "completeness",
        },
        // {
        //   text: "完成時間",
        //   sortable: false,
        //   filterable: false,
        //   value: "completedAt",
        // },
      ],
      debounce: null,
      stats: {
        count: {},
      },
      chartLabels: {
        participants: [],
        gender: [],
        citizenship: [],
      },
    };
  },
  computed: {
    ...mapState({
      isLoggedIn: (state) => state.isLoggedIn,
      ssoUser: (state) => state.ssoUser,
    }),
    merchantId() {
      return this.isLoggedIn ? this.ssoUser.user._json.groups.merchant : null;
    },
    organizationId() {
      return this.isLoggedIn
        ? this.ssoUser.user._json.groups.organization
        : null;
    },
    departmentId() {
      return this.isLoggedIn ? this.ssoUser.user._json.groups.department : null;
    },
    campaignId() {
      return this.campaign.id;
    },
    chartData() {
      if (this.stats.demography) {
        return {
          participants: [
            {
              label: "Participants",
              weight: 9,
              cutout: 60,
              tension: 0.9,
              pointRadius: 2,
              borderWidth: 2,
              backgroundColor: ["#03A9F4", "#3A416F"],
              data: [
                this.stats.count.completed,
                this.stats.count.all - this.stats.count.completed,
              ],
              fill: false,
            },
          ],
          gender: [
            {
              label: "Gender",
              weight: 9,
              cutout: 60,
              tension: 0.9,
              pointRadius: 2,
              borderWidth: 2,
              backgroundColor: ["#03A9F4", "#3A416F"],
              data: [
                this.stats.demography.gender.f,
                this.stats.demography.gender.m,
              ],
              fill: false,
            },
          ],
          citizenship: [
            {
              label: "Citizen",
              weight: 9,
              cutout: 60,
              tension: 0.9,
              pointRadius: 2,
              borderWidth: 2,
              backgroundColor: ["#03A9F4", "#3A416F"],
              data: [
                this.stats.demography.citizenship.yes,
                this.stats.demography.citizenship.no,
              ],
              fill: false,
            },
          ],
        };
      } else {
        return {
          participants: [],
          gender: [],
          citizenship: [],
        };
      }
    },
    affiliates() {
      if (this.stats.demography) {
        let sumGender = Object.values(this.stats.demography.gender).reduce(
          (previousValue, currentValue) => previousValue + currentValue,
          0
        );
        let sumCitizenship = Object.values(
          this.stats.demography.citizenship
        ).reduce(
          (previousValue, currentValue) => previousValue + currentValue,
          0
        );
        return {
          participants: [
            {
              image: null,
              text: this.$i18n.t("chart.Completed"),
              percent: this.stats.count.all
                ? (this.stats.count.completed / this.stats.count.all) * 100
                : 0,
            },
            {
              image: null,
              text: this.$i18n.t("chart.Incompleted"),
              percent: this.stats.count.all
                ? ((this.stats.count.all - this.stats.count.completed) /
                    this.stats.count.all) *
                  100
                : 0,
            },
          ],
          gender: [
            {
              image: null,
              text: this.$i18n.t("chart.Female"),
              percent: sumGender
                ? (this.stats.demography.gender.f / sumGender) * 100
                : 0,
            },
            {
              image: null,
              text: this.$i18n.t("chart.Male"),
              percent: sumGender
                ? (this.stats.demography.gender.m / sumGender) * 100
                : 0,
            },
          ],
          citizenship: [
            {
              image: null,
              text: this.$i18n.t("chart.Citizen"),
              percent: sumCitizenship
                ? (this.stats.demography.citizenship.yes / sumCitizenship) * 100
                : 0,
            },
            {
              image: null,
              text: this.$i18n.t("chart.Guess"),
              percent: sumCitizenship
                ? (this.stats.demography.citizenship.no / sumCitizenship) * 100
                : 0,
            },
          ],
        };
      } else {
        return {
          gender: [],
          citizenship: [],
        };
      }
    },
  },
  watch: {
    // pagination: {
    //   handler() {
    //     this.getDataFromApi();
    //   },
    //   deep: true,
    // },
    campaignId: {
      handler() {
        this.init();
        // this.getDataFromApi();
        // this.getParticipantStats();
      },
    },
    // search: {
    //   handler() {
    //     clearTimeout(this.debounce);
    //     let self = this;
    //     this.debounce = setTimeout(function () {
    //       self.getDataFromApi();
    //     }, 200);
    //   },
    // },
  },
  created() {},
  mounted() {
    // this.endDate = moment().format("YYYY-MM-DD");
    // this.startDate = moment().subtract(7, "days").format("YYYY-MM-DD");
    // campaign期間太長可能會有效能問題
    let startTs = this.campaign.startTime || moment().unix();
    let endTs = this.campaign.endTime || moment().subtract(7, "days").unix();
    this.startDate = moment.unix(startTs).format("YYYY-MM-DD");
    this.endDate = moment.unix(endTs).format("YYYY-MM-DD");
    console.log("participant list: " + JSON.stringify(this.campaign));
    // this.getParticipantStats();

    this.chartLabels.gender = [
      this.$i18n.t("chart.Female"),
      this.$i18n.t("chart.Male"),
    ];
    this.chartLabels.citizenship = [
      this.$i18n.t("chart.Citizen"),
      this.$i18n.t("chart.Guess"),
    ];
    this.chartLabels.participants = [
      this.$i18n.t("chart.Completed"),
      this.$i18n.t("chart.Incompleted"),
    ];
    if (this.campaignId) {
      this.init();
    }
  },
  methods: {
    close() {
      this.details = [];
      this.totalDetails = 0;
      this.close = true;
      this.$emit("close");
    },
    init() {
      this.getDataFromApi();
      this.getParticipantStats();
    },
    getCompletedTime(missions) {
      let completedAt = null;
      // 理論上只會有一個mission
      if (missions) {
        for (const key in missions) {
          for (let i = 0, len = missions[key].length; i < len; i++) {
            if (missions[key][i].status === "completed") {
              completedAt = missions[key][i].completedAt;
            }
          }
          if (completedAt) break;
        }
      }
      return moment.unix(completedAt).format("MM/DD/YYYY HH:mm:ss");
    },
    getDataFromApi() {
      if (!this.campaignId) return;
      const start = moment(this.startDate);
      const end = moment(this.endDate);
      //if (end.diff(start, "days") < 0 || end.diff(start, "months") > 3) {
      if (end.diff(start, "days") < 0) {
        this.$swal({
          // text: "搜尋區間最少為一天，最多為三個月，且開始時間不得小於結束時間",
          text: "搜尋區間最少為一天，且開始時間不得小於結束時間",
          type: "error",
          showCancelButton: false,
          showConfirmButton: true,
          confirmButtonText: this.$i18n.t(`common["Confirm"]`),
          customClass: {
            confirmButton: "btn bg-gradient-success",
            cancelButton: "btn bg-gradient-danger",
          },
        });
        return;
      }

      this.isDetailLoading = true;
      this.getParticipants()
        .then((data) => {
          this.details = [...data.items];
          this.totalDetails = data.total;
        })
        .finally(() => {
          this.isDetailLoading = false;
        });
    },
    getParticipants() {
      console.log("get participants");
      this.details = [];
      return new Promise((resolve, reject) => {
        const { sortBy, sortDesc, page, itemsPerPage } = this.pagination;
        // console.log("pagination", this.pagination);
        const start = moment(this.startDate);
        let end = moment(this.endDate);
        // if (end.diff(start, "days") === 0) {
        //   end = moment(end.format("YYYY-MM-DD") + " 23:59:59");
        // }
        end = moment(end.format("YYYY-MM-DD") + " 23:59:59");
        let query = {
          from: moment(start).unix(),
          to: moment(end).unix(),
          offset: (page - 1) * itemsPerPage || 0,
          limit: itemsPerPage,
          search:
            this.search && this.search.trim()
              ? `phone:${this.search.trim()}`
              : null,
        };
        let items = [];
        let total = 0;
        console.log("query:" + JSON.stringify(query));
        fetchCampaignParticipants(this.merchantId, this.campaignId, query)
          .then((res) => {
            // console.log(
            //   "fetchCampaignParticipants done:" + JSON.stringify(res)
            // );
            items = [...res.data.participants];
            total = res.data.total;
          })
          .catch((e) => {
            console.log("fetchCampaignParticipants failed", e);
            this.$swal({
              text: "無法取得任務參與會員清單",
              type: "error",
              showCancelButton: false,
              confirmButtonText: this.$i18n.t(`common["Confirm"]`),
              customClass: {
                confirmButton: "btn bg-gradient-success",
                cancelButton: "btn bg-gradient-danger",
              },
            });
          })
          .finally(() => {
            setTimeout(() => {
              resolve({
                items,
                total,
              });
            }, 100);
          });
      });
    },
    getParticipantStats() {
      console.log("get getParticipantStats");
      this.isStatsLoaded = false;
      let startTs = this.campaign.startTime || moment(this.startDate).unix();
      let endTs = this.campaign.endTime || moment(this.endDate).unix();

      let query = {
        from: startTs,
        to: endTs,
      };
      fetchCampaignParticipantStats(this.merchantId, this.campaignId, query)
        .then((res) => {
          console.log(
            "fetchCampaignParticipantStats done:" + JSON.stringify(res)
          );
          this.stats = res.data;
        })
        .catch((e) => {
          console.log("fetchCampaignParticipantStats failed", e);
          const message =
            e?.response?.data?.message || "無法取得任務參與狀況統計資料";
          this.$swal({
            text: message,
            type: "error",
            showCancelButton: false,
            confirmButtonText: this.$i18n.t(`common["Confirm"]`),
            customClass: {
              confirmButton: "btn bg-gradient-success",
              cancelButton: "btn bg-gradient-danger",
            },
          });
        })
        .finally(() => {
          this.isStatsLoaded = true;
        });
    },
    showErrorAlert(message) {
      let title = this.$i18n.t(`errors["Update"]`);
      if (this.isNew) {
        title = this.$i18n.t(`errors["Create"]`);
      }
      this.$swal({
        title: title,
        html: message,
        type: "error",
        showCancelButton: false,
        confirmButtonText: this.$i18n.t(`common["Confirm"]`),
        customClass: {
          confirmButton: "btn bg-gradient-success",
          cancelButton: "btn bg-gradient-danger",
        },
      });
    },
  },
};
</script>
<style scoped></style>
