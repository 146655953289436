<template>
  <div class="w-100" v-if="!isLoadingRewardTypes && !isLoadingRewards">
    <v-row class="mt-2">
      <v-col xs="6" sm="4" lg="3" cols="12">
        <v-card class="px-4 py-4 card-shadow border-radius-xl h-100">
          <div class="d-flex flex-column justify-center text-center h-100">
            <a @click="onEditReward(null)" class="text-decoration-none">
              <i class="fa fa-plus text-secondary mb-3" aria-hidden="true"></i>
              <h5 class="text-h5 text-secondary">新增獎勵</h5>
            </a>
          </div>
        </v-card>
      </v-col>
      <v-col
        v-for="r in availableRewards"
        :key="r.id"
        xs="6"
        sm="4"
        lg="3"
        cols="12"
      >
        <v-card class="card-shadow border-radius-xl py-4 text-left">
          <v-row no-gutters class="px-4">
            <v-col cols="12">
              <p
                class="text-md mb-1 text-capitalize text-body font-weight-bold"
              >
                {{ r.rewardName }}
              </p>

              <!-- <h5 class="text-h5 text-typo font-weight-bolder mb-0">
                
              </h5> -->
            </v-col>
          </v-row>
          <v-row class="px-3 mt-1">
            <v-col cols="12" class="pt-0">
              <p class="mb-0 text-body">
                <span class="font-weight-light ms-1"> 獎勵類型：</span>
                <span class="text-success text-sm font-weight-bolder">
                  {{ getRewardType(r.rewardType) }}
                </span>
              </p>
            </v-col>
            <v-col cols="12" class="pt-0">
              <p class="mb-0 text-body">
                <span class="font-weight-light ms-1"> 名額限制：</span>
                <span class="text-success text-sm font-weight-bolder">
                  {{ r.inStock || "無限制" }}
                </span>
              </p>
            </v-col>
            <v-col cols="12" class="pt-0">
              <p class="mb-0 text-body">
                <span class="font-weight-light ms-1"> 獎勵內容：</span>
                <span
                  v-if="r.rewardType === 'points'"
                  class="text-success text-sm font-weight-bolder"
                >
                  <span v-if="r.context.undetermined"> 動態指定 </span>
                  <span v-else>{{ r.context.amount }} 點 </span>
                </span>
                <span
                  v-else-if="r.rewardType === 'coupon'"
                  class="text-success text-sm font-weight-bolder"
                >
                  優惠券{{ r.context.couponId }}：{{ r.context.amount }} 張
                </span>
                <span
                  v-else-if="r.rewardType === 'misc'"
                  class="text-success text-sm font-weight-bolder"
                >
                  {{ r.context.misc }} x{{ r.context.amount }}
                </span>
              </p>
            </v-col>
            <v-col cols="12" class="pt-0">
              <p class="mb-0 text-body">
                <span class="font-weight-light ms-1"> 建立時間：</span>
                <span class="text-success text-sm font-weight-bolder">
                  {{ moment.unix(r.createdAt).format("MM/DD/YYYY HH:mm") }}
                </span>
              </p>
            </v-col>
          </v-row>
          <hr class="dark horizontal mt-3 mb-4" />
          <v-row class="px-4">
            <v-col sm="12" class="">
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                    class="mx-2"
                    dark
                    small
                    color="cyan"
                    v-on="on"
                    @click="onEditReward(r)"
                  >
                    <v-icon>fas fa-edit</v-icon>
                    {{ $t(`common['Edit']`) }}
                  </v-btn>
                  <span class="is-budget-change" v-if="r.isBudgetChange">
                    <v-icon color="red">fas fa-exclamation-triangle</v-icon>
                    點數預算已變更，請重新編輯獎勵
                  </span>
                </template>
                <span> {{ $t(`common['Edit']`) }}</span>
              </v-tooltip>
              <!-- <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                    class="mx-2"
                    dark
                    small
                    color="pink darken-2"
                    v-on="on"
                    @click="onDeleteItem(r)"
                  >
                    <v-icon>fas fa-trash</v-icon>
                    {{ $t(`common['Delete']`) }}
                  </v-btn>
                </template>
                <span> {{ $t(`common['Delete']`) }} </span>
              </v-tooltip> -->
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
    <v-dialog v-model="openEditDialog" persistent max-width="600px">
      <v-card>
        <v-card-title>
          <span class="text-h5">
            {{ isNew ? $t(`common["Add"]`) : $t(`common["Edit"]`) }}
          </span>
          <span class="text-h5"> 任務獎勵 </span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-form ref="editForm" lazy-validation>
              <v-row>
                <v-col cols="12" sm="6">
                  <v-text-field
                    v-model="editItem.rewardName"
                    label="獎勵名稱"
                    class="font-size-input input-style"
                    :rules="rules.required"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-select
                    :items="availableRewardTypes"
                    v-model="editItem.rewardType"
                    label="獎勵類型"
                    item-text="name"
                    item-value="type"
                    class="font-size-input input-style"
                    :rules="rules.required"
                  >
                  </v-select>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-row>
                    <v-col cols="6">
                      <v-text-field
                        v-model="editItem.inStock"
                        label="名額限制"
                        type="number"
                        class="font-size-input input-style"
                        :rules="
                          editItem.checkbox === true ? [] : rules.required
                        "
                        @input="
                          editItem.inStock = $event !== '' ? $event : null
                        "
                      ></v-text-field>
                    </v-col>
                    <v-col cols="6">
                      <v-checkbox
                        class="large-checkbox pt-2"
                        v-model="editItem.checkbox"
                        label="無限制"
                      >
                      </v-checkbox>
                    </v-col>
                  </v-row>
                </v-col>

                <v-col cols="12" sm="6">
                  <v-row>
                    <v-col cols="12" v-if="editItem.rewardType === 'points'">
                      <v-select
                        v-model="editItem.context.pointId"
                        :items="points"
                        item-text="name"
                        item-value="id"
                        :menu-props="{ closeOnContentClick: true }"
                        :label="$t(`common['Select Point Setting']`)"
                        :rules="
                          editItem.rewardType === 'points' ? rules.required : []
                        "
                      >
                      </v-select>
                    </v-col>
                    <v-col cols="12" v-if="editItem.rewardType === 'coupon'">
                      <v-text-field
                        v-model="editItem.context.couponId"
                        label="優惠券代碼"
                        :rules="
                          editItem.rewardType === 'coupon' ? rules.required : []
                        "
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" v-if="editItem.rewardType === 'misc'">
                      <v-text-field
                        v-model="editItem.context.misc"
                        label="獎項"
                        :rules="
                          editItem.rewardType === 'coupon' ? rules.required : []
                        "
                      ></v-text-field>
                    </v-col>

                    <v-col :cols="editItem.rewardType === 'points' ? 6 : 12">
                      <v-text-field
                        v-model="editItem.context.amount"
                        :label="
                          editItem.rewardType === 'points'
                            ? '點數數量'
                            : editItem.rewardType === 'coupon'
                            ? '優惠券數量'
                            : '數量'
                        "
                        type="number"
                        :rules="
                          editItem.rewardType === 'points' &&
                          editItem.context.undetermined
                            ? []
                            : rules.required
                        "
                      ></v-text-field>
                    </v-col>
                    <v-col cols="6" v-if="editItem.rewardType === 'points'">
                      <v-checkbox
                        class="large-checkbox pt-3"
                        v-model="editItem.context.undetermined"
                        label="動態指定"
                      >
                      </v-checkbox>
                    </v-col>
                  </v-row>
                </v-col>
                <!-- 
                <v-col
                  v-else-if="editItem.rewardType === 'coupon'"
                  cols="12"
                  sm="6"
                >
                  <v-row>
                    <v-col cols="12">
                      <v-text-field
                        v-model="editItem.context.couponId"
                        label="優惠券代碼"
                        :rules="
                          editItem.rewardType === 'coupon' ? rules.required : []
                        "
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-text-field
                        v-model="editItem.context.amount"
                        label="數量"
                        :rules="
                          editItem.rewardType === 'coupon' ? rules.required : []
                        "
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col
                  v-else-if="editItem.rewardType === 'misc'"
                  cols="12"
                  sm="6"
                >
                  <v-row>
                    <v-col cols="12">
                      <v-text-field
                        v-model="editItem.context.misc"
                        label="其它獎勵"
                        :rules="
                          editItem.rewardType === 'misc' ? rules.required : []
                        "
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-text-field
                        v-model="editItem.context.amount"
                        label="數量"
                        :rules="
                          editItem.rewardType === 'misc' ? rules.required : []
                        "
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-col> -->
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="closeEditDialog">
            {{ $t(`common['Close']`) }}
          </v-btn>
          <v-btn color="blue darken-1" text @click="onSaveEditItem">
            {{ $t(`common['Save']`) }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import moment from "moment";
import { mapState } from "vuex";

import {
  fetchRewardTypes,
  fetchCampaignRewards,
  updateReward,
  deleteCampaignReward,
} from "src/apis/campaignCore";

export default {
  name: "Rewards",
  props: {
    points: {
      type: Array,
      default: function () {
        return [];
      },
    },
    // campaign: {
    //   type: Object,
    //   default: function () {
    //     return {};
    //   },
    // },
    campaignId: {
      type: String,
      default: null,
      required: true,
    },
    useBudget: {
      type: Boolean,
      default: false,
      required: true,
    },
  },
  components: {},
  data() {
    return {
      moment: moment,
      isProcessing: false,
      isLoadingRewards: false,
      isLoadingRewardTypes: false,
      rewards: [],
      availableTypes: [],
      isNew: false,
      openEditDialog: false,
      rules: {
        required: [(v) => !!v || this.$t(`common["Required"]`)],
      },
      defaultEditItem: {
        name: null,
        rewardType: null,
        inStock: null,
        checkbox: false,
        context: {
          pointId: null,
          amount: null,
          undetermined: false,
        },
      },
      editItem: {
        name: null,
        rewardType: null,
        context: {
          pointId: null,
          amount: null,
          undetermined: false,
        },
        checkbox: false,
      },
    };
  },
  computed: {
    ...mapState({
      isLoggedIn: (state) => state.isLoggedIn,
      ssoUser: (state) => state.ssoUser,
    }),
    isActionDisabled() {
      return (
        this.isProcessing || this.isLoadingRewards || this.isLoadingRewardTypes
      );
    },
    merchantId() {
      return this.isLoggedIn ? this.ssoUser.user._json.groups.merchant : null;
    },
    organizationId() {
      return this.isLoggedIn
        ? this.ssoUser.user._json.groups.organization
        : null;
    },
    availableRewards() {
      return this.rewards.map((reward) => {
        console.log("reward:", reward);
        const existed = this.points.find(
          (p) => p.id === reward.context.pointId
        );
        reward.isBudgetChange = existed ? false : true;
        return reward;
      });
    },
    availableRewardTypes() {
      if (this.useBudget) {
        return this.availableTypes;
      } else {
        return this.availableTypes.filter((item) => item.type !== "points");
      }
    },
  },
  watch: {
    // 點數設定有變動，代表任務預算有變
    // 此時重新取得一次獎勵，才可以重新判斷availableRewards
    // 有沒有更好的作法....
    points: {
      handler() {
        this.getRewards();
      },
      deep: true,
    },
  },
  created() {
    this.unwatchIsLoggedIn = this.$watch("isLoggedIn", (newVal) => {
      if (newVal) {
        this.unwatchIsLoggedIn();
        this.getRewardTypes();
      }
    });
    this.unwatchCampaignId = this.$watch("campaignId", (newVal) => {
      if (newVal) {
        this.unwatchCampaignId();
        this.getRewards();
      }
    });
  },
  mounted() {
    if (this.isLoggedIn) {
      this.getRewardTypes();
      // this.getRewards();
    }
    if (this.campaignId) {
      this.getRewards();
    }
  },
  methods: {
    getRewardType(type) {
      let parsedType = this.availableTypes.find((t) => t.type === type);
      return parsedType ? parsedType.name : "Unknown";
    },
    getRewardTypes() {
      this.isLoadingRewardTypes = true;
      let query = {};
      fetchRewardTypes(this.merchantId, query)
        .then((res) => {
          console.log("fetchRewardTypes done", res);
          this.availableTypes = [...res.data];
        })
        .catch((e) => {
          console.log("fetchRewardTypes failed", e);
        })
        .finally(() => {
          this.isLoadingRewardTypes = false;
        });
    },
    getRewards() {
      this.isLoadingRewards = true;
      let query = {};
      console.log("getRewards campaign:" + this.campaignId);
      fetchCampaignRewards(this.merchantId, this.campaignId, query)
        .then((res) => {
          console.log("fetchCampaignRewards done", res);
          this.rewards = [...res.data];
        })
        .catch((e) => {
          console.log("fetchCampaignRewards failed", e);
        })
        .finally(() => {
          this.isLoadingRewards = false;
        });
    },
    closeEditDialog() {
      this.$refs.editForm.resetValidation();
      // this.$refs.editForm.reset();
      this.openEditDialog = false;
    },
    onEditReward(item) {
      if (item) {
        // 判斷是否修正選擇的點數
        if (item.context && item.context.pointId) {
          const existed = this.points.find(
            (p) => p.id === item.context.pointId
          );
          if (!existed) {
            item.context.pointId = null;
          }
        }
        this.editItem = Object.assign({}, item);
        this.editItem.checkbox = this.editItem.inStock ? false : true;

        this.isNew = false;
      } else {
        this.editItem = Object.assign({}, this.defaultEditItem);
        if (this.useBudget) {
          this.editItem.rewardType = "points";
        } else {
          this.editItem.rewardType = "coupon";
        }
        this.editItem.context.pointId = this.points.length
          ? this.points[0].id
          : null;
        this.editItem.checkbox = false;
        this.isNew = true;
      }
      console.log("isNew:" + this.isNew);

      this.openEditDialog = true;
    },
    onSaveEditItem() {
      const valid = this.$refs.editForm.validate();
      this.editItem.name = this.editItem.rewardName;
      this.editItem.type = this.editItem.rewardType;
      this.editItem.id = this.editItem.rewardId;
      if (valid) {
        updateReward(this.merchantId, this.campaignId, this.editItem)
          .then((res) => {
            console.log("updateReward done", res);
            this.getRewards();
            this.openEditDialog = false;
            this.$refs.editForm.resetValidation();
            this.$refs.editForm.reset();
          })
          .catch((e) => {
            console.log("updateReward failed", e);
            let errmsg = this.isNew ? "新增任務獎勵失敗" : "更新任務獎勵失敗";
            if (e.response && e.response.data && e.response.data.message) {
              errmsg += "<br/>" + e.response.data.message;
            }
            this.showErrorAlert(errmsg);
          });
      } else {
        this.showErrorAlert(this.$i18n.t(`errors["Incorrect Fields"]`));
      }
    },
    showErrorAlert(message) {
      let title = this.$i18n.t(`errors["Update"]`);
      if (this.isNew) {
        title = this.$i18n.t(`errors["Create"]`);
      }
      this.$swal({
        title: title,
        html: message,
        type: "error",
        showCancelButton: false,
        confirmButtonText: this.$i18n.t(`common["Confirm"]`),
        customClass: {
          confirmButton: "btn bg-gradient-success",
          cancelButton: "btn bg-gradient-danger",
        },
      });
    },
    onDeleteItem(item) {
      this.$swal({
        title: `刪除任務獎勵`,
        html: `是否確定刪除「<b>${item.rewardName}</b>」？
          <br/><br/> 刪除後無法恢復`,
        type: "info",
        showCancelButton: true,
        confirmButtonText: this.$i18n.t(`common["Confirm"]`),
        cancelButtonText: this.$i18n.t(`common["Cancel"]`),
        customClass: {
          confirmButton: "btn bg-gradient-success",
          cancelButton: "btn bg-gradient-danger",
        },
      }).then((res) => {
        if (res.isConfirmed) {
          this.onDeleteReward(item);
        }
      });
    },
    onDeleteReward(item) {
      deleteCampaignReward(this.merchantId, this.campaignId, item.rewarId)
        .then((res) => {
          console.log("deleteCampaignReward done: ", res);
          this.$swal({
            title: "刪除成功",
            html: `已成功刪除任務獎勵<br/> <b>${item.rewardName}</b>`,
            type: "sccess",
            showCancelButton: false,
            confirmButtonText: this.$i18n.t(`common["Confirm"]`),
            customClass: {
              confirmButton: "btn bg-gradient-success",
              cancelButton: "btn bg-gradient-danger",
            },
          });
          this.getRewards();
        })
        .catch((e) => {
          console.log("deleteCampaignReward failed", e);
          let errmsg = `無法刪除任務獎勵「<b>${item.rewardName}</b>」`;
          if (e.response && e.response.data && e.response.data.message) {
            errmsg += "<br/>" + e.response.data.message;
          }
          const title = "刪除失敗";
          this.showErrorAlert(errmsg, title);
        });
    },
  },
};
</script>
<style scoped>
.large-checkbox::v-deep i {
  font-size: 24px;
}
.large-checkbox::v-deep label {
  font-size: 16px;
  padding-left: 3px;
}
.large-checkbox::v-deep .v-radio {
  padding: 0px;
}
.is-budget-change {
  color: red;
}
</style>
