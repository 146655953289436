<template>
  <div class="w-100">
    <v-row align="stretch">
      <v-col
        v-if="availableMissionTypes.length === 1"
        xs="6"
        sm="4"
        lg="3"
        cols="12"
      >
        <v-card class="px-4 py-4 card-shadow border-radius-xl h-100">
          <div class="d-flex flex-column justify-center text-center h-100">
            <a
              @click="onEditItem(null, availableMissionTypes[0].type)"
              class="text-decoration-none"
            >
              <i class="fa fa-plus text-secondary mb-3" aria-hidden="true"></i>
              <h5 class="text-h5 text-secondary">新增關卡</h5>
            </a>
          </div>
        </v-card>
      </v-col>
      <v-dialog v-else v-model="missionTypeDialog" persistent max-width="800px">
        <template v-slot:activator="{ on }">
          <v-col xs="6" sm="4" lg="3" cols="12">
            <v-card class="px-4 py-4 card-shadow border-radius-xl h-100">
              <div class="d-flex flex-column justify-center text-center h-100">
                <a v-on="on" class="text-decoration-none">
                  <i
                    class="fa fa-plus text-secondary mb-3"
                    aria-hidden="true"
                  ></i>
                  <h5 class="text-h5 text-secondary">新增關卡</h5>
                </a>
              </div>
            </v-card>
          </v-col>
        </template>
        <v-card>
          <v-card-title>
            <span class="headline">請選擇關卡類型</span>
          </v-card-title>

          <v-card-text>
            <v-container>
              <v-form ref="missionTypeForm">
                <v-row>
                  <v-col
                    v-for="type in availableMissionTypes"
                    :key="type.type"
                    cols="12"
                    sm="6"
                  >
                    <v-hover v-slot="{ hover }">
                      <v-card
                        :class="{ 'on-hover': hover }"
                        class="px-4 py-4 card-shadow border-radius-xl h-100"
                      >
                        <div
                          class="
                            d-flex
                            flex-column
                            justify-center
                            text-center
                            h-100
                          "
                        >
                          <a
                            @click="onEditItem(null, type.type)"
                            class="text-decoration-none"
                          >
                            <!-- <i
                              class="fa fa-lg fa-crosshairs text-secondary mb-3"
                              aria-hidden="true"
                            ></i> -->
                            <h5 class="text-h5 text-secondary">
                              {{ type.name }}
                            </h5>
                            <p
                              class="
                                text-sm
                                mt-2
                                mb-1
                                text-capitalize text-body
                                font-weight-light
                              "
                            >
                              {{ type.description }}
                            </p>
                          </a>
                        </div>
                      </v-card>
                    </v-hover>
                  </v-col>
                </v-row>
              </v-form>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="blue darken-1"
              text
              @click="missionTypeDialog = false"
            >
              {{ $t(`common['Cancel']`) }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!-- <v-col xs="6" sm="4" lg="3" cols="12">
        <v-card class="px-4 py-4 card-shadow border-radius-xl h-100">
          <div class="d-flex flex-column justify-center text-center h-100">
            <a @click="onEditItem(null)" class="text-decoration-none">
              <i class="fa fa-plus text-secondary mb-3" aria-hidden="true"></i>
              <h5 class="text-h5 text-secondary">新增關卡</h5>
            </a>
          </div>
        </v-card>
      </v-col> -->
      <v-col v-for="m in missions" :key="m.id" cols="12" xs="6" sm="4" lg="3">
        <v-card
          class="card-shadow border-radius-xl py-4 flex d-flex flex-column"
        >
          <!-- <v-card-title primary-title>
            <p class="text-md mb-1 text-capitalize text-body font-weight-bold">
              {{ m.missionName }}
            </p>
          </v-card-title> -->
          <v-row no-gutters class="px-4">
            <v-col cols="12">
              <p
                class="text-md mb-1 text-capitalize text-body font-weight-bold"
              >
                {{ m.missionName }}
              </p>
            </v-col>
            <v-col cols="12">
              <div
                class="
                  text-sm
                  mt-2
                  mb-1
                  text-capitalize text-body
                  font-weight-light
                  descClass
                "
              >
                {{ m.missionDesc || "&nbsp" }}
              </div>
            </v-col>
          </v-row>
          <v-row class="px-3 mt-1">
            <v-col cols="12" class="pt-0">
              <p class="mb-0 text-body">
                <span class="font-weight-light ms-1"> 關卡類型：</span>
                <span class="text-success text-sm font-weight-bolder">
                  {{ getMissionType(m.missionType) }}
                </span>
              </p>
            </v-col>
            <!-- <v-col cols="12" class="pt-0">
              <p class="mb-0 text-body">
                <span class="font-weight-light ms-1"> 關卡獎勵：</span>
                <span
                  v-if="m.data && m.data.rewardId"
                  class="text-success text-sm font-weight-bolder"
                >
                  {{ getReward(m.data.rewardId) }}
                </span>
              </p>
            </v-col> -->
            <v-col cols="12" class="pt-0" v-if="false">
              <p class="mb-0 text-body">
                <span class="font-weight-light ms-1"> 可重複領取：</span>
                <span
                  v-if="m.data && m.data.multiCollectible"
                  class="text-success text-sm font-weight-bolder"
                >
                  {{ $t(`common['Yes']`) }}
                </span>
                <span v-else class="text-success text-sm font-weight-bolder">
                  {{ $t(`common['No']`) }}
                </span>
              </p>
            </v-col>
            <v-col cols="12" class="pt-0">
              <p class="mb-0 text-body">
                <span class="font-weight-light ms-1"> 建立時間：</span>
                <span class="text-success text-sm font-weight-bolder">
                  {{ moment.unix(m.createdAt).format("MM/DD/YYYY HH:mm") }}
                </span>
              </p>
            </v-col>
          </v-row>
          <hr class="dark horizontal mt-3 mb-4" />
          <v-row class="px-4">
            <v-col sm="12" class="">
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                    class="mx-2"
                    dark
                    small
                    color="cyan"
                    v-on="on"
                    @click="onEditItem(m)"
                  >
                    <v-icon>fas fa-edit</v-icon>
                    {{ $t(`common['Edit']`) }}
                  </v-btn>
                </template>
                <span>{{ $t(`common['Edit']`) }}</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                    class="mx-2"
                    dark
                    small
                    color="pink darken-2"
                    v-on="on"
                    @click="onDeleteItem(m)"
                  >
                    <v-icon>fas fa-trash</v-icon>
                    {{ $t(`common['Delete']`) }}
                  </v-btn>
                </template>
                <span> {{ $t(`common['Delete']`) }} </span>
              </v-tooltip>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
    <v-dialog v-model="openEditDialog" persistent max-width="800px">
      <component
        v-if="openEditDialog"
        :ref="'editForm'"
        :is="componentName"
        :campaign-id="campaignId"
        :input-item="editItem"
        :mission-name="getMissionType(editItem.missionType)"
        @close="closeEditDialog"
        @save="onSaveEditItem"
      />
    </v-dialog>
  </div>
</template>

<script>
import moment from "moment";
import { mapState } from "vuex";

import {
  fetchCampaignRewards,
  fetchMissionTypes,
  fetchCampaignMissions,
  deleteCampaignMission,
} from "src/apis/campaignCore";
import BasicMissionForm from "./BasicMissionForm.vue";
import QAForm from "./QAForm.vue";
import QuestionnaireForm from "./QuestionnaireForm.vue";
import LuckyDrawForm from "./LuckyDrawForm.vue";
import StampCollectionForm from "./StampCollectionForm.vue";
import VideoForm from "./VideoForm.vue";
import BarcodeForm from "./BarcodeForm.vue";
import SlotMachine from "./SlotMachine.vue";

export default {
  name: "Missions",
  props: {
    campaignId: {
      type: String,
      default: null,
      required: true,
    },
    campaignPrototype: {
      type: String,
      default: "",
      required: false,
    },
  },
  components: {
    BasicMissionForm,
    QuestionnaireForm,
    QAForm,
    LuckyDrawForm,
    StampCollectionForm,
    VideoForm,
    BarcodeForm,
    SlotMachine,
  },
  data() {
    return {
      moment: moment,
      componentName: null,
      isProcessing: false,
      isLoadingRewards: false,
      isLoadingMissions: false,
      isLoadingMissionTypes: false,
      missionTypeDialog: false,
      rewards: [],
      missions: [],
      availableTypes: [],
      isNew: false,
      openEditDialog: false,
      rules: {
        required: [(v) => !!v || this.$t(`common["Required"]`)],
      },
      defaultEditItem: {
        name: null,
        desc: null,
        data: {
          // rewardId: null,
          // multiCollectible: null,
        },
      },
      editItem: {
        name: null,
      },
      itemsPerPageArray: [4, 8, 12],
      search: "",
      filter: {},
      sortDesc: false,
      page: 1,
      itemsPerPage: 4,
      missionMeta: {},
    };
  },
  computed: {
    ...mapState({
      isLoggedIn: (state) => state.isLoggedIn,
      ssoUser: (state) => state.ssoUser,
    }),
    isActionDisabled() {
      return (
        this.isProcessing ||
        this.isLoadingMissions ||
        this.isLoadingMissionTypes
      );
    },
    merchantId() {
      return this.isLoggedIn ? this.ssoUser.user._json.groups.merchant : null;
    },
    organizationId() {
      return this.isLoggedIn
        ? this.ssoUser.user._json.groups.organization
        : null;
    },
    numberOfPages() {
      return Math.ceil(this.items.length / this.itemsPerPage);
    },
    availableMissionTypes() {
      let types = [];
      if (this.availableTypes) {
        switch (this.campaignPrototype) {
          case "qna":
          case "questionnaire":
          case "vote":
            types = this.availableTypes.filter((t) => t.type === "question");
            break;
          case "video":
          case "referral":
            types = this.availableTypes.filter((t) =>
              t.type.includes(this.campaignPrototype)
            );
            break;
          case "minigame":
            types = this.availableTypes.filter(
              (t) => t.type === "luckydraw" || t.type === "slot-machine"
            );
            break;
          case "check-in":
          case "registration":
          case "event-driven":
          case "donate-invoice":
          case "code-exchange":
          case "stamp-collection":
            types = this.availableTypes.filter(
              (t) => t.type === this.campaignPrototype
            );
            break;
          default:
            // for legacy campaign which does not have prototype
            types = this.availableTypes;
            break;
        }
      }
      return types;
    },
  },
  watch: {},
  created() {
    this.unwatchIsLoggedIn = this.$watch("isLoggedIn", (newVal) => {
      if (newVal) {
        this.unwatchIsLoggedIn();
        this.getMissionTypes();
      }
    });
    this.unwatchCampaignId = this.$watch("campaignId", (newVal) => {
      if (newVal) {
        this.unwatchCampaignId();
        this.getMissions();
        this.getRewards();
      }
    });
  },
  mounted() {
    if (this.isLoggedIn) {
      this.getMissionTypes();
    }
    if (this.campaignId) {
      this.getMissions();
      this.getRewards();
    }
  },
  methods: {
    showComponent(values) {
      switch (values) {
        case "question":
        case "qtn":
          if (this.campaignPrototype === "qna") this.componentName = "QAForm";
          else this.componentName = "QuestionnaireForm";
          break;
        case "stamp-collection":
          this.componentName = "StampCollectionForm";
          break;
        case "luckydraw":
          this.componentName = "LuckyDrawForm";
          break;
        case "slot-machine":
          this.componentName = "SlotMachine";
          break;
        case "video-promo":
          this.componentName = "VideoForm";
          break;
        case "code-exchange":
          this.componentName = "BarcodeForm";
          break;
        default:
          this.componentName = "BasicMissionForm";
          break;
      }
    },
    getReward(id) {
      let filtered = this.rewards.find((r) => r.id === id);
      return filtered ? filtered.name : null;
    },
    getRewards() {
      this.isLoadingRewards = true;
      let query = {};
      console.log("getRewards campaign:" + this.campaignId);
      fetchCampaignRewards(this.merchantId, this.campaignId, query)
        .then((res) => {
          console.log("fetchCampaignRewards done", res);
          this.rewards = [...res.data];
        })
        .catch((e) => {
          console.log("fetchCampaignRewards failed", e);
        })
        .finally(() => {
          this.isLoadingRewards = false;
        });
    },
    getMissionType(type) {
      let parsedType = this.availableTypes.find((t) => t.type === type);
      return parsedType ? parsedType.name : "Unknown";
    },
    getMissionTypes() {
      this.isLoadingMissionTypes = true;
      let query = {};
      fetchMissionTypes(this.merchantId, query)
        .then((res) => {
          console.log("fetchMissionTypes done", res);
          this.availableTypes = [...res.data];
        })
        .catch((e) => {
          console.log("fetchMissionTypes failed", e);
        })
        .finally(() => {
          this.isLoadingMissionTypes = false;
        });
    },
    getMissions() {
      this.isLoadingMissions = true;
      let query = {};
      console.log("getMissions campaign:" + this.campaignId);
      fetchCampaignMissions(this.merchantId, this.campaignId, query)
        .then((res) => {
          console.log("fetchCampaignMissions done", res);
          this.missions = [...res.data];
        })
        .catch((e) => {
          console.log("fetchCampaignMissions failed", e);
        })
        .finally(() => {
          this.isLoadingRewards = false;
        });
    },
    closeEditDialog() {
      // this.$refs.editForm.resetValidation();
      //  this.$refs.editForm.reset();
      this.openEditDialog = false;
    },
    onEditItem(item, missionType) {
      console.log("edit mission: " + missionType, item);
      this.missionTypeDialog = false;
      if (item) {
        this.editItem = Object.assign({}, item);
        //TBD, 目前getMissions不會回對應獎勵
        this.editItem.data = {
          rewardId: null,
          multiCollectible: null,
        };
        this.isNew = false;
      } else {
        this.editItem = Object.assign({}, this.defaultEditItem);
        this.editItem.missionType = missionType;
        this.isNew = true;
      }
      console.log("isNew:" + this.isNew);
      this.showComponent(this.editItem.missionType);

      this.openEditDialog = true;
    },
    onSaveEditItem() {
      this.getMissions();
      this.openEditDialog = false;
      // const valid = this.$refs.editForm.validate();
      // if (valid) {
      //   updateMission(this.merchantId, this.campaignId, this.editItem)
      //     .then((res) => {
      //       console.log("updateMission done", res);
      //       this.openEditDialog = false;
      //       this.$refs.editForm.resetValidation();
      //       this.$refs.editForm.reset();
      //     })
      //     .catch((e) => {
      //       console.log("updateMission failed", e);
      //       let errmsg = this.isNew ? "新增任務關卡失敗" : "更新任務關卡失敗";
      //       if (e.response && e.response.data && e.response.data.message) {
      //         errmsg += "<br/>" + e.response.data.message;
      //       }
      //       this.showErrorAlert(errmsg);
      //     });
      // } else {
      //   this.showErrorAlert(this.$i18n.t(`errors["Incorrect Fields"]`));
      // }
    },
    showErrorAlert(message) {
      let title = this.$i18n.t(`errors["Update"]`);
      if (this.isNew) {
        title = this.$i18n.t(`errors["Create"]`);
      }
      this.$swal({
        title: title,
        html: message,
        type: "error",
        showCancelButton: false,
        confirmButtonText: this.$i18n.t(`common["Confirm"]`),
        customClass: {
          confirmButton: "btn bg-gradient-success",
          cancelButton: "btn bg-gradient-danger",
        },
      });
    },
    onDeleteItem(item) {
      this.$swal({
        title: `刪除任務關卡`,
        html: `是否確定刪除「<b>${item.missionName}</b>」？
          <br/><br/> 刪除後無法恢復`,
        type: "info",
        showCancelButton: true,
        confirmButtonText: this.$i18n.t(`common["Confirm"]`),
        cancelButtonText: this.$i18n.t(`common["Cancel"]`),
        customClass: {
          confirmButton: "btn bg-gradient-success",
          cancelButton: "btn bg-gradient-danger",
        },
      }).then((res) => {
        if (res.isConfirmed) {
          this.onDeleteMission(item);
        }
      });
    },
    onDeleteMission(item) {
      deleteCampaignMission(this.merchantId, this.campaignId, item.missionId)
        .then((res) => {
          console.log("deleteCampaignMission done: ", res);
          this.$swal({
            title: "刪除成功",
            html: `已成功刪除任務關卡<br/> <b>${item.missionName}</b>`,
            type: "sccess",
            showCancelButton: false,
            confirmButtonText: this.$i18n.t(`common["Confirm"]`),
            customClass: {
              confirmButton: "btn bg-gradient-success",
              cancelButton: "btn bg-gradient-danger",
            },
          });
          this.getMissions();
        })
        .catch((e) => {
          console.log("deleteCampaignMission failed", e);
          let errmsg = `無法刪除任務關卡「<b>${item.rewardName}</b>」`;
          if (e.response && e.response.data && e.response.data.message) {
            errmsg += "<br/>" + e.response.data.message;
          }
          const title = "刪除失敗";
          this.showErrorAlert(errmsg, title);
        });
    },
  },
};
</script>
<style scoped>
.v-card.on-hover {
  background-color: #d3e4e8;
}
.descClass {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
</style>
