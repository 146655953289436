// import axios from "axios";

const version = "v1";
const campaignPath = "/core/campaigns/";
export function fetchCampaignTypes(merchantId, querystring) {
  var config = {
    method: "get",
    url: `${process.env.VUE_APP_COREAPIHOST}${campaignPath}${version}/campaign-prototypes`,
    params: querystring,
  };

  return window.qcsso.request(config);
}

export function fetchMissionTypes(merchantId, querystring) {
  var config = {
    method: "get",
    url: `${process.env.VUE_APP_COREAPIHOST}${campaignPath}${version}/mission-types`,
    params: querystring,
  };

  return window.qcsso.request(config);
}

export function fetchCampaignMissions(merchantId, campaignId, querystring) {
  var config = {
    method: "get",
    url: `${process.env.VUE_APP_COREAPIHOST}${campaignPath}${version}/merchants/${merchantId}/campaigns/${campaignId}/missions`,
    params: querystring,
  };

  return window.qcsso.request(config);
}

export function fetchRewardTypes(merchantId, querystring) {
  var config = {
    method: "get",
    url: `${process.env.VUE_APP_COREAPIHOST}${campaignPath}${version}/reward-types`,
    params: querystring,
  };

  return window.qcsso.request(config);
}

export function fetchCampaignRewards(merchantId, campaignId, querystring) {
  var config = {
    method: "get",
    url: `${process.env.VUE_APP_COREAPIHOST}${campaignPath}${version}/merchants/${merchantId}/campaigns/${campaignId}/rewards`,
    params: querystring,
  };

  return window.qcsso.request(config);
}

export function updateCampaign(merchantId, data) {
  var config = {
    method: "post",
    url: `${process.env.VUE_APP_COREAPIHOST}${campaignPath}${version}/merchants/${merchantId}/campaigns/`,
    data,
  };
  if (data.id) {
    config = {
      method: "put",
      url: `${process.env.VUE_APP_COREAPIHOST}${campaignPath}${version}/merchants/${merchantId}/campaigns/${data.id}`,
      data,
    };
  }
  return window.qcsso.request(config);
}

export function updateMission(merchantId, campaignId, data) {
  var config = {
    method: "post",
    url: `${process.env.VUE_APP_COREAPIHOST}${campaignPath}${version}/merchants/${merchantId}/campaigns/${campaignId}/build-mission2`,
    data,
  };
  if (data.missionId) {
    config = {
      method: "put",
      url: `${process.env.VUE_APP_COREAPIHOST}${campaignPath}${version}/merchants/${merchantId}/campaigns/${campaignId}/missions/${data.missionId}`,
      data,
    };
  }
  return window.qcsso.request(config);
}

export function updateReward(merchantId, campaignId, data) {
  var config = {
    method: "post",
    url: `${process.env.VUE_APP_COREAPIHOST}${campaignPath}${version}/merchants/${merchantId}/campaigns/${campaignId}/rewards`,
    data,
  };
  if (data.id) {
    config = {
      method: "put",
      url: `${process.env.VUE_APP_COREAPIHOST}${campaignPath}${version}/merchants/${merchantId}/campaigns/${campaignId}/rewards/${data.id}`,
      data,
    };
  }
  return window.qcsso.request(config);
}

// 送審
export function submitCampaign(merchantId, campaignId, data) {
  var config = {
    method: "put",
    url: `${process.env.VUE_APP_COREAPIHOST}${campaignPath}${version}/merchants/${merchantId}/campaigns/${campaignId}/submit`,
    data,
  };
  return window.qcsso.request(config);
}

// 完成任務審核
export function approveCampaign(merchantId, campaignId, data) {
  var config = {
    method: "put",
    url: `${process.env.VUE_APP_COREAPIHOST}${campaignPath}${version}/merchants/${merchantId}/campaigns/${campaignId}/approve`,
    data,
  };
  return window.qcsso.request(config);
}

// 拒絕任務審核
export function rejectCampaign(merchantId, campaignId, data) {
  var config = {
    method: "put",
    url: `${process.env.VUE_APP_COREAPIHOST}${campaignPath}${version}/merchants/${merchantId}/campaigns/${campaignId}/reject`,
    data,
  };
  return window.qcsso.request(config);
}

// 刪除
export function deleteCampaign(merchantId, campaignId, data) {
  var config = {
    method: "delete",
    url: `${process.env.VUE_APP_COREAPIHOST}${campaignPath}${version}/merchants/${merchantId}/campaigns/${campaignId}`,
    data,
  };
  return window.qcsso.request(config);
}

export function deleteCampaignReward(merchantId, campaignId, rewardId, data) {
  var config = {
    method: "delete",
    url: `${process.env.VUE_APP_COREAPIHOST}${campaignPath}${version}/merchants/${merchantId}/campaigns/${campaignId}/rewards/${rewardId}`,
    data,
  };
  return window.qcsso.request(config);
}

export function deleteCampaignMission(merchantId, campaignId, missionId, data) {
  var config = {
    method: "delete",
    url: `${process.env.VUE_APP_COREAPIHOST}${campaignPath}${version}/merchants/${merchantId}/campaigns/${campaignId}/missions/${missionId}`,
    data,
  };
  return window.qcsso.request(config);
}

export function fetchCampaignParticipants(merchantId, campaignId, querystring) {
  var config = {
    method: "get",
    url: `${process.env.VUE_APP_COREAPIHOST}${campaignPath}${version}/merchants/${merchantId}/campaigns/${campaignId}/participants`,
    params: querystring,
  };

  return window.qcsso.request(config);
}

export function fetchCampaignParticipantStats(
  merchantId,
  campaignId,
  querystring
) {
  var config = {
    method: "get",
    url: `${process.env.VUE_APP_COREAPIHOST}${campaignPath}${version}/merchants/${merchantId}/campaigns/${campaignId}/participant-stats`,
    params: querystring,
  };

  return window.qcsso.request(config);
}
